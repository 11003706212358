import React, { useRef, useState } from 'react';
import moment from 'moment';

import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import approvePic from '../../assets/icons/approve.png'
import declinePic from '../../assets/icons/decline.png'
import statusApproveIcon from '../../assets/icons/status_approve.png';
import statusAbandonedIcon from '../../assets/icons/status_abandoned.png';
import statusEmptyIcon from '../../assets/icons/status_empty.png';
import statusFailedIcon from '../../assets/icons/status_failed.png';
import statusSuccessIcon from '../../assets/icons/status_success.png';
import redeployIcon from '../../assets/icons/redeploy.svg';
import codeIcon from '../../assets/icons/code.png';
import codeDeactiveIcon from '../../assets/icons/code_deactive.png';
import retryIcon from '../../assets/icons/retry.png';
import retryDeactiveIcon from '../../assets/icons/retry_deactive.png';
import CommonTooltip from '../../components/ui/CommonTooltip';

const useStyles = makeStyles((theme) => ({
    itemContent: {
        color: '#4D4E53',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: 1,
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative'
    },
    moreIconBtn: {
        position: 'absolute',
        left: '6px',
        top: '12px',
        padding: '6px',
        '&.Mui-disabled': {
            opacity: 0.45,
        },
        '&:hover': {
            backgroundColor: '#F7EED7'
        }
    },
    actionNameContent: {
        padding: '12px 0 12px 52px',
        flex: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 0,
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    actionDateContent: {
        padding: '6px 0 6px 22px',
        width: '448px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    halfSizeActionDateContent: {
        width: '296px'
    },
    actionNameText: {
        paddingLeft: '16px',
        display: 'inline-block',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    actionDateTxt: {
        fontSize: '13px',
        lineHeight: '20px'
    },
    statusSuccess: {
        color: '#11A38B',
        width: '36px',
        height: '36px',
    },
    statusFailed: {
        color: '#E83646',
        width: '36px',
        height: '36px',
    },
    statusIcon: {
        width: '36px',
        height: '36px',
    },
    statusOngoing: {
        color: '#D8B200',
        width: '36px !important',
        height: '36px !important',
    },
    emptyIcon: {
        width: '36px',
        height: '36px',
        background: '#FFF',
        borderRadius: '50%',
        display: 'inline-block',
        boxSizing: 'border-box',
        border: '2px dashed #CCCCCC'
    },
    approvalActionItem: {
        padding: '20px 26px',
        color: '#4C4948',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '14px',
        cursor: 'pointer',
        '&:hover': {
            background: '#F7EED7'
        }
    },

    approvalActionIcon: {
        width: '18px',
        height: '18px',
        marginRight: '8px',
        position: 'relative',
        top: '3px'
    },
    retryBtn: {
        padding: '8px',
        paddingLeft: '10px',
        color: '#4C4948',
        borderColor: 'currentColor',
        borderWidth: '1px',
        fontSize: '13px'
    },
    logBtn: {
        padding: '6px',
        marginRight: '45px',
        '&:hover': {
            backgroundColor: '#F7EED7'
        }
    },
    helfSizeLogBtn: {
        marginRight: '0',
    },
    retryIcon: {
        width: '18px',
        height: '18px',
    },
    logIcon: {
        width: '32px',
        height: '32px',
    }
}));

const ActionItem = (props) => {
    const classes = useStyles();
    const approvalEle = useRef();
    const [approvalPopoverOpen, setApprovalPopoverOpen] = useState(false);
    const { action, handleApprove, handleRetry, handleShowLog, handleRedeploy, isHalfSize } = props;

    const onApprovalItemClick = (status) => {
        setApprovalPopoverOpen(false);
        handleApprove(status, action);
    }

    let statusEle = null;
    let desc = action.output?.executionResult?.externalExecutionSummary ?? '-';
    switch (action.status) {
        case 'InProgress':
            if (['APPROVE-IN-TEST', 'APPROVE-IN-PREPROD', 'APPROVE-IN-QA', 'APPROVE-IN-DATALAKE-QA', 'APPROVE-IN-PROD', 'APPROVE-IN-DATALAKE-PROD', 'Approve', 'START-LOAD-TEST', 'DESTROY-LOAD-TEST-STACK'].includes(action.actionName)) {
                statusEle = <>
                    <IconButton className={classes.moreIconBtn} ref={approvalEle} onClick={() => setApprovalPopoverOpen(true)}>
                        <MoreVertIcon />
                    </IconButton>
                    <CommonTooltip title={action.status}>
                        <img src={statusApproveIcon} alt="approve" className={classes.statusIcon} />
                    </CommonTooltip>
                    <Popover
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={approvalPopoverOpen}
                        anchorEl={approvalEle.current}
                        onClose={() => setApprovalPopoverOpen(false)}
                    >
                        <Typography className={classes.approvalActionItem} onClick={() => onApprovalItemClick("Approved")}><img className={classes.approvalActionIcon} src={approvePic} alt='trigger' />Approve</Typography>
                        <Typography className={classes.approvalActionItem} onClick={() => onApprovalItemClick("Rejected")}><img className={classes.approvalActionIcon} src={declinePic} alt='trigger' />Decline</Typography>
                    </Popover>
                </>
                if (desc === '-') {
                    desc = "Manual approval required"
                }
            } else {
                statusEle = <CommonTooltip title={action.status}>
                    <CircularProgress className={classes.statusOngoing} />
                </CommonTooltip>
            }
            break;

        case 'Abandoned':
            statusEle = <CommonTooltip title={action.status}>
                <img src={statusAbandonedIcon} alt="abandoned" className={classes.statusIcon} />
            </CommonTooltip>
            if (desc === '-') {
                desc = 'Abandoned';
            }
            break;

        case 'Succeeded':
            statusEle = <>
                {
                    action.enabledRedeploy &&
                    <CommonTooltip title="Redeploy">
                        <IconButton className={classes.moreIconBtn} onClick={() => handleRedeploy(action)}>
                            <img src={redeployIcon} alt="redeploy" />
                        </IconButton>
                    </CommonTooltip>
                }
                <CommonTooltip title="Success">
                    <img src={statusSuccessIcon} alt="success" className={classes.statusIcon} />
                </CommonTooltip>
            </>
            if (desc === '-') {
                if (["DEPLOY-IN-PROD", "DEPLOY-IN-QA", "DEPLOY-IN-TEST", "DEPLOY-IN-DATALAKE-QA", "DEPLOY-IN-DATALAKE-PROD", "DEPLOY", "Test-Deploy", "Deploy"].includes(action.actionName)) {
                    desc = "Deployment succeeded"
                } else if (action.actionName === 'LOAD-TEST') {
                    desc = "Load Test succeeded"
                } else if (action.actionName === 'DESTROY') {
                    desc = "Destroy succeeded"
                } else if (['BUILD', 'Build'].includes(action.actionName)) {
                    desc = "Build succeeded"
                } else if (action.actionName === 'PLAN') {
                    desc = "Plan succeeded"
                } else if (['APPROVE-IN-DATALAKE-PROD', 'Approve'].includes(action.actionName)) {
                    desc = "Approved"
                } else if (['Test', 'COMPONENT-TEST'].includes(action.actionName)) {
                    desc = "Test succeeded"
                } else if (["SAST", "SAST-Scan", "SAST-SCAN"].includes(action.actionName)) {
                    desc = "Scan succeeded"
                }
            }
            break;

        case 'Failed':
            statusEle = <CommonTooltip title={action.status} >
                <img src={statusFailedIcon} alt="failed" className={classes.statusIcon} />
            </CommonTooltip>
            if (desc === '-') {
                if (["DEPLOY-IN-PROD", "DEPLOY-IN-QA", "DEPLOY-IN-TEST", "DEPLOY-IN-DATALAKE-QA", "DEPLOY-IN-DATALAKE-PROD", "DEPLOY", "Test-Deploy", "Deploy"].includes(action.actionName)) {
                    desc = "Deployment failed"
                } else if (action.actionName === 'LOAD-TEST') {
                    desc = "Load Test failed"
                } else if (action.actionName === 'DESTROY') {
                    desc = "Destroy failed"
                } else if (['BUILD', 'Build'].includes(action.actionName)) {
                    desc = "Build failed"
                } else if (action.actionName === 'PLAN') {
                    desc = "Plan failed"
                } else if (['APPROVE-IN-DATALAKE-PROD', 'Approve'].includes(action.actionName)) {
                    desc = "Rejected"
                } else if (['Test', 'COMPONENT-TEST'].includes(action.actionName)) {
                    desc = "Test failed"
                } else if (["SAST", "SAST-Scan", "SAST-SCAN"].includes(action.actionName)) {
                    desc = "Scan failed"
                }
            }
            break;

        default:
            statusEle = <CommonTooltip title={action.status} >
                <img src={statusEmptyIcon} alt="empty" className={classes.statusIcon} />
            </CommonTooltip>;
            break;
    }

    return <Box className={classes.itemContent}>
        <Box className={classes.actionNameContent}>
            {statusEle}
            <Typography className={classes.actionNameText}>{desc}</Typography>
        </Box>
        <Box className={`${classes.actionDateContent}${isHalfSize ? ` ${classes.halfSizeActionDateContent}` : ''}`}>
            <Box>
                <Typography className={classes.actionDateTxt}>{moment(action.startTime).format('YYYY-MM-DD HH:mm')}</Typography>
                <Typography className={classes.actionDateTxt}>{action.status === 'InProgress' ? '-' : moment(action.lastUpdateTime).format('YYYY-MM-DD HH:mm')}</Typography>
            </Box>
            <Button variant="outlined" className={classes.retryBtn} startIcon={<img src={action.retryEnable ? retryIcon : retryDeactiveIcon} alt="retry" className={classes.retryIcon} />} disabled={!action.retryEnable} onClick={() => handleRetry(action)}>Retry Stage</Button>
            <IconButton className={`${classes.logBtn}${isHalfSize ? ` ${classes.helfSizeLogBtn}` : ''}`} disabled={action.disabledLog} onClick={() => handleShowLog(action)} >
                <img src={action.disabledLog ? codeDeactiveIcon : codeIcon} alt="retry" className={classes.logIcon} />
            </IconButton>
        </Box>
    </Box>
}

export const EmptyActionItem = () => {
    const classes = useStyles();

    return <Box className={classes.itemContent}>
        <Typography className={classes.actionNameContent}>
            <CommonTooltip title="Empty" >
                <div className={classes.emptyIcon}></div>
            </CommonTooltip>
            <span className={classes.actionNameText}>Un-occurred</span>
        </Typography>
        <Box className={classes.actionDateContent}>
        </Box>
    </Box>
}

export default ActionItem;
