import React, { useEffect, useMemo, useState } from "react";
import { Box, makeStyles, Popover, Typography } from "@material-ui/core";
import { TEAM_COLOR_MAP } from ".";

const useStyles = makeStyles((theme) => ({
  popoverContainer: {
    '& .MuiPopover-paper': {
        borderRadius: '0'
    }
  },
  container: {
    background: "#FFFFFF",
    width: "270px",
    display: "flex",
    flexDirection: "column",
  },
  tabContainer: {
    display: "flex",
    flexDirection: "row"
  },
  tabItem: {
    flex: 1,
    borderTop: "3px solid #ECECEC",
    borderBottom: '.5px solid #c4c4c4',
    alignItems: 'center',
    textAlign: 'center',
    color: '#4D4E53',
    fontSize: '12px',
    lineHeight: '45px',
    cursor: 'pointer',
    '&.active': {
        borderTopColor: '#E66400',
        background: '#F7EED7',
        fontWeight: 600,
    }
  },
  listContainer: {
    overflow: 'auto',
    height: "360px",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    padding: '0 16px',
    justifyContent: 'space-between',
    '& p': {
        lineHeight: '45px',
        color: '#4D4E53',
        fontSize: '12px',
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '22px',
        verticalAlign: 'middle'
    }
  },
  itemDot: {
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '6px',
    verticalAlign: 'middle'
  },
  itemValue: {
    textAlign: 'right'
  },
  summaryContainer: {
    display: "flex",
    flexDirection: "row",
    padding: '0 16px',
    justifyContent: 'space-between',
    borderTop: '.5px solid #c4c4c4',
    '& p': {
        lineHeight: '45px',
        color: '#4D4E53',
        fontSize: '12px',
        fontWeight: 600,
    }
}
}));

const VulnerabilityDetailDialog = ({ open, anchorPosition, data, level, onClose }) => {
  const classes = useStyles();
  const [tab, setTab] = useState('productArea');

  useEffect(() => {
    const onResize = () => {
      open && onClose();
    };
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [open, onClose]);

  const totalIssuesNumber = data.reduce((total, item) => {
    return total + (item.summary[level] ?? 0)
  }, 0);

  const list = useMemo(() => {
    let result = null;
    if (tab === 'productArea') {
        const paMap = {};

        data.forEach(item => {
            paMap[item.owner] = (paMap[item.owner] ?? 0) + item.summary[level];
        });
        result = Object.entries(paMap).map(([team, number]) => {
            return {
                key: team,
                value: number,
                color: TEAM_COLOR_MAP[team]
            }
        });
    } else {
        result = data.map(item => {
            return {
                key: item.component_name,
                value: item.summary[level] ?? 0
            }
        });
    }

    return result.sort((a, b) => b.value - a.value);

  }, [data, level, tab]);

  return (
    <Popover
      className={classes.popoverContainer}
      anchorReference="anchorPosition"
      open={open}
      anchorPosition={anchorPosition}
      onClose={onClose}
    >
      <Box className={classes.container}>
        <Box className={classes.tabContainer}>
          <Typography onClick={() => setTab('productArea')} className={`${classes.tabItem}${tab === 'productArea' ? ' active': ''}`}>Product Area</Typography>
          <Typography onClick={() => setTab('component')} className={`${classes.tabItem}${tab === 'component' ? ' active': ''}`}>Component</Typography>
        </Box>
        <Box className={classes.listContainer}>
            {list.map(item => <Box key={item.key} className={classes.itemContainer}>
            <Typography title={item.key}>
                {item.color && <span className={classes.itemDot} style={{background: item.color}}></span>}
                {item.key}
            </Typography>
            <Typography title={item.value} className={classes.itemValue}>{item.value}</Typography>
        </Box>)}
        </Box>
        <Box className={classes.summaryContainer}>
            <Typography>{level} issues</Typography>
            <Typography>{totalIssuesNumber}</Typography>
        </Box>
      </Box>
    </Popover>
  );
};

export default VulnerabilityDetailDialog;
