import React, { useState, useEffect, useMemo } from "react";
import AWS from "aws-sdk";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import ParamsArea from "./ParamsArea";
import VulnerabilityGraph from "./VulnerabilityGraph";
import DashboardNav from "../Home/DashboardNav";
import { useStateGettersContext } from "../../store";
import { cb2Promise, useHandleAwsAuthErr } from "../../common/AwsUtil";
import { removeDuplicate } from "../../common/Util";
import VulnerabilityTable from "./VulnerabilityTable";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    gap: "26px",
    width: "100%",
  },
  mainContainer: {
    flex: "auto",
    overflow: "hidden",
  },
  paramsContainer: {
    width: "350px",
    flexShrink: 0,
  },
}));

export const COLOR_MAP = {
  Critical: "#D20A04",
  High: "#E66400",
  Medium: "#D8B200",
  Low: "#B4A451",
};

export const TEAM_COLOR_MAP = {
  ffs: "#C5C0A6",
  rms: "#F7C57E",
  ads: "#F7E380",
  saas: "#FEB0F1",
  cors: "#FEB0F1",
  fw: "#DDF9FF",
  ims: "#97D9BF",
};

const Vulnerabilityreport = () => {
  const storeGetters = useStateGettersContext();
  const classes = useStyles();
  const handleAwsAuthErr = useHandleAwsAuthErr();

  const [reportJson, setReportJson] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    filters: {
      team: {
        condition: "Includes",
        values: [],
      },
      component: {
        condition: "Includes",
        values: [],
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setReportJson(null);
      setIsLoading(true);
      try {
        const s3 = new AWS.S3(storeGetters.readonlyKeys);

        const s3Params = {
          Bucket: `ud-pipeline-artifacts-372158068517`,
          Key: `sast-scan-report-aggregated/aggregated-scan-report.json`,
        };
        const data = await cb2Promise(s3, "getObject", s3Params);
        const jsonData = JSON.parse(data.Body.toString("utf-8"));
        setReportJson(jsonData?.aggregated_report ?? []);
      } catch (err) {
        if (!handleAwsAuthErr(err)) {
          console.error("Error fetching data:from S3: ", err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [storeGetters.readonlyKeys, handleAwsAuthErr]);

  const filteredData = useMemo(() => {
    return (reportJson ?? []).filter((item) => {
      if (params.filters.team.values.length > 0) {
        if (
          params.filters.team.condition === "Includes" &&
          !params.filters.team.values.includes(item.owner)
        ) {
          return false;
        }
        if (
          params.filters.team.condition === "Excludes" &&
          params.filters.team.values.includes(item.owner)
        ) {
          return false;
        }
      }

      if (params.filters.component.values.length > 0) {
        if (
          params.filters.component.condition === "Includes" &&
          !params.filters.component.values.includes(item.component_name)
        ) {
          return false;
        }
        if (
          params.filters.component.condition === "Excludes" &&
          params.filters.component.values.includes(item.component_name)
        ) {
          return false;
        }
      }

      return true;
    });
  }, [params, reportJson]);

  const productOwners = useMemo(() => {
    if (!reportJson) return [];
    return removeDuplicate(reportJson.map((item) => item.owner));
  }, [reportJson]);

  const componentNames = useMemo(() => {
    if (!reportJson) return [];
    return removeDuplicate(reportJson.map((item) => item.component_name));
  }, [reportJson]);

  return (
    <Box className={classes.container}>
      <Box className={classes.mainContainer}>
        <DashboardNav />
        <VulnerabilityGraph
          loading={isLoading}
          data={filteredData}
        />
        <VulnerabilityTable
          loading={isLoading}
          data={filteredData}
        />
      </Box>
      <Box className={classes.paramsContainer}>
        <ParamsArea
          value={params}
          onChange={setParams}
          productOwners={productOwners}
          componentNames={componentNames}
        />
      </Box>
    </Box>
  );
};

export default Vulnerabilityreport;
