import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

import arrowPic from '../../assets/icons/arrow.png'
import ActionItem from './ActionItem';
import ActionTitle from './ActionTitle';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        flex: 1,
    },
    arrow: {
        margin: '12px 0 12px 56px',
        width: '24px',
        height: 'auto'
    },
    interalArrow: {
        position: 'absolute',
        left: '60px',
        bottom: '-10px',
        width: '18px',
        height: 'auto',
        zIndex: 1,
    },
    actionContainer: {
        borderBottom: '0.5px solid #C4C4C4',
    },
    actionItemContainer: {
        position: 'relative'
    }
}));

const BuildRender = (props) => {
    const classes = useStyles();
    const { actions, hasArraw, title, handleApprove, handleRetry, handleShowLog, handleRedeploy, isHalfSize } = props;

    return actions.length > 0 ? <Box className={classes.wrapper}>
        <ActionTitle stageName={title} isHalfSize={isHalfSize} />
        <Box className={classes.actionContainer}>
            {
                actions.map((item, index) => (
                    <Box className={classes.actionItemContainer} key={item.actionExecutionId} >
                        <ActionItem isHalfSize={isHalfSize} action={item} handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} />
                        { index !== actions.length - 1 && <img className={classes.interalArrow} src={arrowPic} alt="arrow" />}
                    </Box>
                ))
            }
        </Box>
        {hasArraw && <img className={classes.arrow} src={arrowPic} alt="arrow" />}
    </Box> : <></>
}

export default BuildRender;