import React from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import AppNavBar from '../../components/app/AppNavBar';
import { makeStyles } from "@material-ui/core/styles";
import Help from '../Help';
import HomeNav from './HomeNav';
import Pipelines from '../Pipelines';
import BackToTop from './BackToTop';
import Project from '../Project';
import Bookmark from '../Bookmark';
import Execution from '../Execution';
import Costreport from '../Costreport';
import Vulnerabilityreport from '../Vulnerabilityreport';
import Datalakecatalog from '../Datalakecatalog';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentContainer: {
        flex: 1,
        overflow: 'auto'
    },
    subcontentContainer: {
        padding: '30px 24px'
    }
}));

const Home = function () {
    const classes = useStyles();
    return <div className={classes.container}>
        <AppNavBar />

        <div className={classes.contentContainer}>
            <Switch>
                <Route path='/help' exact>
                    <Help />
                </Route>
                <Route path='/execution/:projectname/:id' exact>
                    <Execution />
                </Route>
                <Route path='/'>
                    <div className={classes.subcontentContainer}>
                        <HomeNav />
                        <Switch>
                            <Route path='/bookmarks' exact>
                                <Bookmark />
                            </Route>
                            <Route path='/pipelines' exact>
                                <Pipelines />
                            </Route>
                            <Route path='/project/:id' exact>
                                <Project />
                            </Route>
                            <Route path="/dashboard" exact>
                                <Redirect to='/dashboard/cost' />
                            </Route>
                            <Route path="/dashboard/cost" exact>
                                <Costreport />
                            </Route>
                            <Route path="/dashboard/vulnerability" exact>
                                <Vulnerabilityreport />
                            </Route>
                            <Route path="/" exact>
                                <Redirect to='/bookmarks' />
                            </Route>
                            <Route path="/datalakecatalog" exact>
                                <Datalakecatalog />
                            </Route>
                            <Route path="*">
                                <Redirect to='/404' />
                            </Route>
                        </Switch>
                    </div>
                </Route>
            </Switch>
            <BackToTop />
        </div>
    </div>
}

export default Home;