import { Box, Button, ButtonGroup, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect,useRef, useMemo, useState } from 'react';
import Decimal from 'decimal.js';
import { BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Bar, LineChart, Line, Tooltip } from 'recharts';
import barChartIcon from '../../assets/icons/bar_chart.svg';
import lineChartIcon from '../../assets/icons/line_chart.svg';
import stackedBarChartIcon from '../../assets/icons/stacked_bar_chart.svg';
import moment from 'moment';

export const COLOR_LIST = ['#97D9BF', '#A3DADF', '#F7E380', '#C5C0A6', '#F7C57E', '#F5827F', '#FEB0F1', '#CAA9FC', '#B4DDF8', '#D6D6D6'];

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFFFFF',
    padding: '8px 24px',
    fontFamily: 'Roboto',
  },
  smallContainer: {
    background: '#FFFFFF',
    padding: '12px 12px',
    fontFamily: 'Roboto',
  },
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  typeTxt: {
    fontWeight: 600,
    fontSize: '12px',
  },
  smallCostTxt: {
    position: 'absolute',
    top: '28px',
    left: '14px'
  },
  typeBtn: {
    padding: '8px 20px',
    borderColor: '#A1A1A1',
    '&.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child)': {
      borderLeftColor: 'transparent'
    },
  },
  activeTypeBtn: {
    background: '#F7EED7'
  },
  graphContainer: {
    marginTop: '12px',
    height: 'calc(100vh - 388px);'
  },
  smallGraphContainer: {
    height: '224px'
  },
  tooltipContainer: {
    background: '#FFFFFF',
    width: '270px',
    border: '0.5px solid #C4C4C4'
  },
  tooltipTitle: {
    borderBottom: '0.5px solid #C4C4C4',
    lineHeight: '45px',
    paddingLeft: '16px',
    color: '#4D4E53',
    fontSize: '12px',
    fontWeight: 600
  },
  tooltipContent: {
  },
  tooltipItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '35px',

  },
  tooltipItemDot: {
    width: '14px',
    height: '14px',
    marginLeft: '16px',
    borderRadius: '50%'
  },
  tooltipItemName: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#4D4E53',
    fontSize: '12px',
    fontWeight: 400,
    paddingLeft: '6px'
  },
  tooltipItemMoney: {
    width: '66px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#4D4E53',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'right',
    paddingRight: '16px'
  },
  tooltipSummary: {
    borderTop: '0.5px solid #C4C4C4',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tooltipSummaryTxt: {
    lineHeight: '45px',
    color: '#4D4E53',
    fontSize: '12px',
    fontWeight: 600,
  },
  legendContainer: {
    paddingTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px 16px',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  smallLegendContainer: {
    paddingBottom: '16px',
    paddingLeft: '124px',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px 16px',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  legendDot: {
    width: '14px',
    height: '14px',
    borderRadius: '50%'
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  legendTxt: {
    fontSize: '12px',
    fontWeight: 400,
    paddingLeft: '6px',
  },
  loadingContainer: {
    textAlign: 'center',
    paddingTop: '40px'
  },
  loading: {
    color: '#E66400',
    marginLeft: '6px',
    position: 'relative',
    top: '1px'
  },
}));

export const XAxisTick = (props) => {
  const { payload, className, x, height, index, orientation, stroke, width, y, textAnchor } = props;

  return (
    <text className={className} height={height} textAnchor={textAnchor} index={index} orientation={orientation} stroke={stroke} width={width} y={y} fill="#4D4E53" fontSize='12px'>
      <tspan x={x} dy="14px">{payload.value}</tspan>
    </text>
  );
}

const CustomTooltip = (props) => {
  const classes = useStyles();
  const ele = useRef();

  useEffect(() => {
    if (ele.current && props?.onHeightChange) {
      props.onHeightChange(ele.current.clientHeight);
    }
  }, [props])
  const title = props.payload?.[0]?.payload?.label ?? '';
  const totalCost = props.payload.reduce((total, item) => {
    return Decimal.add(item.value, total).toNumber();
  }, '0').toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return <Box ref={ele} className={classes.tooltipContainer}>
    <Typography className={classes.tooltipTitle}>{title}</Typography>
    <Box className={classes.tooltipContent}>
      {
        props.payload.map((item) => {
          return <Box key={item.dataKey} className={classes.tooltipItem}>
            <div className={classes.tooltipItemDot} style={{ background: item.color }}></div>
            <Typography className={classes.tooltipItemName}>{item.name}</Typography>
            <Typography className={classes.tooltipItemMoney}>{
              new Decimal(item.value).toNumber().toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })
            }</Typography>
          </Box>
        })
      }
    </Box>
    <Box className={classes.tooltipSummary}>
      <Typography className={classes.tooltipSummaryTxt}>Total costs</Typography>
      <Typography className={classes.tooltipSummaryTxt}>{totalCost}</Typography>
    </Box>
  </Box>
}

export const CustomizedLegend = (props) => {
  const classes = useStyles();
  const { payload, type } = props;

  return (
    <Box className={type === 'small' ? classes.smallLegendContainer : classes.legendContainer}>
      {
        payload.map(item => (
          <Box key={item.dataKey ?? item.value} className={classes.legendItem}>
            <div className={classes.legendDot} style={{ background: item.color }}></div>
            <Typography className={classes.legendTxt}>{item.value}</Typography>
          </Box>
        ))
      }
    </Box>
  );
}

const CostGraph = (props) => {
  const classes = useStyles();
  const { detailedData, granularity, type, loading } = props;
  const [graphType, setGraphType] = useState('stackedBar');

  const subDimissions = useMemo(() => {
    if (!detailedData) return [];
    const allItemsMap = {};
    detailedData.ResultsByTime.forEach((item) => {
      item.Groups.forEach(item => {
        if (allItemsMap[item.Keys[0]]) {
          allItemsMap[item.Keys[0]] = Decimal.add(allItemsMap[item.Keys[0]], item.Metrics.UnblendedCost.Amount).toString();
        } else {
          allItemsMap[item.Keys[0]] = item.Metrics.UnblendedCost.Amount;
        }
      })
    });

    const sortedlist = Object.entries(allItemsMap).sort((a, b) => b[1] - a[1]);

    const barList = sortedlist.slice(0, 9).map((item, index) => {
      return {
        dataKey: item[0],
        fill: COLOR_LIST[index],
        stroke: COLOR_LIST[index]
      }
    })
    if (sortedlist.length < 10) return barList;
    if (sortedlist.length === 10) {
      barList.push({
        dataKey: sortedlist[9][0],
        fill: COLOR_LIST[9],
        stroke: COLOR_LIST[9]
      });
    } else {
      barList.push({
        dataKey: 'Other',
        fill: COLOR_LIST[9],
        stroke: COLOR_LIST[9]
      });
    }
    return barList;
  }, [detailedData])

  const chartData = useMemo(() => {
    if (!detailedData) return [];

    const dimissionKeys = subDimissions.map(item => item.dataKey);

    return detailedData.ResultsByTime.map(item => {
      let label = item.TimePeriod.Start
      if (granularity === 'MONTHLY') {
        label = moment(label, 'YYYY-MM-DD').format('MMM YYYY')
      }
      const returnItem = {
        label,
        Other: 0
      };

      item.Groups.forEach((groupItem) => {
        if (dimissionKeys.includes(groupItem.Keys[0])) {
          returnItem[groupItem.Keys[0]] = groupItem.Metrics.UnblendedCost.Amount;
        } else {
          returnItem.Other = Decimal.add(groupItem.Metrics.UnblendedCost.Amount, returnItem.Other).toString();
        }
      });

      Object.entries(returnItem).forEach(([key, value]) => {
        if (key === 'label') return;
        returnItem[key] = Number(new Decimal(value).toFixed(5));
      });

      return returnItem;
    });
  }, [detailedData, subDimissions, granularity]);
  const graphContainerEle = useRef();

  let [tooltipTop, setTooltipTop] = useState();
  const onTooltipHeightChange = useCallback((tooltipHeight) => {
    if (type === 'small') {
      setTooltipTop(undefined);
      return;
    }
    let graphHeight = graphContainerEle.current?.clientHeight;

    if (tooltipHeight && graphHeight && tooltipHeight > graphHeight) {
      setTooltipTop(graphHeight - tooltipHeight);
    } else {
      setTooltipTop(undefined);
    }
  }, [graphContainerEle, type]);

  const commonGraphComponents = (<>
    <CartesianGrid strokeDasharray="5 3" vertical={false} />
    <XAxis dataKey="label" tick={<XAxisTick />} minTickGap={10} axisLine={{ stroke: '#A6A6A6' }} tickLine={{ stroke: '#A6A6A6' }} />
    <YAxis axisLine={false} tickLine={false} tick={{ fill: "#4D4E53", fontSize: '12px' }} />
    <Tooltip position={tooltipTop ? {y: tooltipTop} : undefined} cursor={false} wrapperStyle={{zIndex: 1}} content={<CustomTooltip onHeightChange={onTooltipHeightChange} />} />
    <Legend align={type === 'small' ? 'left' : 'center'} verticalAlign={type === 'small' ? 'top' : 'bottom'} content={<CustomizedLegend type={type} />} />
  </>);

  return <Box className={type === 'small' ? classes.smallContainer : classes.container}>
    <Box className={classes.typeContainer}>
      <Typography className={`${classes.typeTxt} ${type === 'small' ? classes.smallCostTxt : ''}`}>Costs ($)</Typography>
      {
        type !== 'small' && <ButtonGroup className={classes.btnsTypeContainer}>
          <Button onClick={() => setGraphType('bar')} className={`${classes.typeBtn} ${graphType === 'bar' ? classes.activeTypeBtn : ''}`} ><img src={barChartIcon} alt='Bar' /></Button>
          <Button onClick={() => setGraphType('stackedBar')} className={`${classes.typeBtn} ${graphType === 'stackedBar' ? classes.activeTypeBtn : ''}`}><img src={stackedBarChartIcon} alt='Stacked Bar' /></Button>
          <Button onClick={() => setGraphType('line')} className={`${classes.typeBtn} ${graphType === 'line' ? classes.activeTypeBtn : ''}`}><img src={lineChartIcon} alt='Line' /></Button>
        </ButtonGroup>
      }
    </Box>
    <Box ref={graphContainerEle} className={type === 'small' ? classes.smallGraphContainer : classes.graphContainer}>
      {
        loading ? <Box className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} size={32} />
        </Box> : <ResponsiveContainer width="100%" height="100%">
          {
            graphType === 'bar' && <BarChart
              width={500}
              height={300}
              barGap={0}
              data={chartData}
            >
              {commonGraphComponents}
              {
                subDimissions.map(item => <Bar key={item.dataKey} {...item} maxBarSize={60} />)
              }
            </BarChart>
          }
          {
            graphType === 'stackedBar' && <BarChart
              width={500}
              margin={{ left: type === 'small' ? -36 : 5 }}
              height={300}
              data={chartData}
            >
              {commonGraphComponents}
              {
                subDimissions.map(item => <Bar key={item.dataKey} stackId='a' {...item} maxBarSize={type === 'small' ? 15 : 60} />)
              }
            </BarChart>
          }
          {
            graphType === 'line' && <LineChart
              width={500}
              height={300}
              data={chartData}
            >
              {commonGraphComponents}
              {
                subDimissions.map(item => <Line key={item.dataKey} strokeWidth='3px' {...item} />)
              }
            </LineChart>
          }

        </ResponsiveContainer>
      }


    </Box>
  </Box>

}

export default CostGraph;