import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AWS from "aws-sdk";
import { useDispatchReducerContext, useStateGettersContext } from '../../store';
import { cb2Promise, useGetAssumedKeysForDeployAccounts, useHandleAwsAuthErr } from "../../common/AwsUtil";
import { SHOW_TOAST } from "../../store/dispatchNames";

function useLambdaEnv(projectName, pipetype, pipelineStages, actionExecutionList, isDataLakeAccount, loadTrigger, getCodeDeployParams, setEnvVersionMap) {
    const handleAwsAuthErr = useHandleAwsAuthErr();
    
    useEffect(() => {
        if (!projectName || !pipetype || pipetype.toLowerCase() !== 'lambda') return;
        const getAliasesInfo = async () => {
            const env = isDataLakeAccount ? ['datalake-qa', 'datalake-prod']: ['test', 'qa', 'prod'];
            const aliasesInfo = await Promise.all(env.map(item => {
                const fn = async () => {
                    const params = await getCodeDeployParams(item);
                    if (!params) return {
                        env: `DEPLOY-IN-${item.toUpperCase()}`,
                        status: 'NO_PERMISSION',
                        pipelineVersion: null
                    };
                    const lambda = new AWS.Lambda(params);

                    try {
                        const data = await cb2Promise(lambda, 'getFunction', {
                            Qualifier: 'live',
                            FunctionName: projectName
                        });
                        return {
                            env: `DEPLOY-IN-${item.toUpperCase()}`,
                            status: 'Succeeded',
                            pipelineVersion: data?.Configuration?.Environment?.Variables?.PIPELINE_VERSION
                        };
                    } catch (err) {
                        if (!handleAwsAuthErr(err)) {
                            return {
                                env: `DEPLOY-IN-${item.toUpperCase()}`,
                                status: 'Failed',
                                pipelineVersion: null
                            };
                        }
                    }

                }
                return fn();
            }));

            setEnvVersionMap(aliasesInfo);
        }

        getAliasesInfo();
    }, [pipetype, projectName, getCodeDeployParams, handleAwsAuthErr, pipelineStages, setEnvVersionMap, isDataLakeAccount, actionExecutionList, loadTrigger]);

}

function useEC2Env(projectName, pipetype, pipelineStages, actionExecutionList, loadTrigger, getCodeDeployParams, setEnvExecutionIdMap) {
    const storeGetters = useStateGettersContext();
    const dispatch = useDispatchReducerContext();
    const handleAwsAuthErr = useHandleAwsAuthErr();

    const deployConfigMap = useMemo(() => {
        if (!pipetype || pipetype.toLowerCase() !== 'application' || !pipelineStages) return null;
        return pipelineStages.reduce((total, item) => {
            if (["DEPLOY-IN-TEST", "DEPLOY-IN-QA", "DEPLOY-IN-PROD"].includes(item.name)) {
                const deployInfo = item.actions.find((action) => action.name === item.name)
                if (deployInfo) {
                    total.push({
                        ...deployInfo.configuration,
                        env: item.name,
                    });
                }
            }
            return total;
        }, []);
    }, [pipetype, pipelineStages]);

    useEffect(() => {
        if (!deployConfigMap || deployConfigMap.length === 0 || !projectName || !pipetype || pipetype.toLowerCase() !== 'application') return;
        const getEnvInfos = async () => {
            try {
                const codepipeline = new AWS.CodePipeline(storeGetters.readonlyKeys);
                const envS3Map = await Promise.all(deployConfigMap.map(item => {
                    const fn = async () => {
                        let env = null;
                        switch (item.env) {
                            case "DEPLOY-IN-TEST":
                                env = 'test'
                                break;
                            case "DEPLOY-IN-QA":
                                env = 'qa'
                                break;
                            case "DEPLOY-IN-PROD":
                                env = 'prod'
                                break;

                            default:
                                break;
                        }
                        const params = await getCodeDeployParams(env);
                        if (!params) return {
                            env: item.env,
                            status: 'NO_PERMISSION'
                        };
                        const codedeploy = new AWS.CodeDeploy(params);
                        const { deployments } = await cb2Promise(codedeploy, 'listDeployments', {
                            applicationName: item.ApplicationName,
                            deploymentGroupName: item.DeploymentGroupName,
                            includeOnlyStatuses: [
                                "InProgress", "Succeeded"
                            ],
                        });

                        const { deploymentInfo } = await cb2Promise(codedeploy, 'getDeployment', {
                            deploymentId: deployments[0]
                        });

                        return {
                            env: item.env,
                            s3Location: deploymentInfo.revision.s3Location,
                            status: deploymentInfo.status
                        };
                    }
                    return fn();
                }));

                const envExecutionIdMap = envS3Map.filter(item => item.status === 'NO_PERMISSION');

                if (actionExecutionList) {
                    actionExecutionList.find(item => {
                        if (item.actionName !== 'BUILD') return false;
                        envS3Map.forEach((envS3MapItem) => {
                            if (envS3MapItem.s3Location && item.output?.outputArtifacts?.[0]?.s3location?.bucket === envS3MapItem.s3Location.bucket && item.output?.outputArtifacts?.[0]?.s3location?.key === envS3MapItem.s3Location.key) {
                                envExecutionIdMap.push({
                                    env: envS3MapItem.env,
                                    status: envS3MapItem.status,
                                    pipelineExecutionId: item.pipelineExecutionId
                                })
                            }
                        })

                        return envExecutionIdMap.length === envS3Map.length;
                    });
                } else {
                    let nextTokenForListActionExecutions = undefined;
                    let turnPageNumber = 0;

                    do {
                        const { actionExecutionDetails, nextToken } = await cb2Promise(codepipeline, 'listActionExecutions', {
                            pipelineName: projectName,
                            nextToken: nextTokenForListActionExecutions
                        });

                        nextTokenForListActionExecutions = nextToken;

                        actionExecutionDetails.find(item => {
                            if (item.actionName !== 'BUILD') return false;
                            envS3Map.forEach((envS3MapItem) => {
                                if (envS3MapItem.s3Location && item.output?.outputArtifacts?.[0]?.s3location?.bucket === envS3MapItem.s3Location.bucket && item.output?.outputArtifacts?.[0]?.s3location?.key === envS3MapItem.s3Location.key) {
                                    envExecutionIdMap.push({
                                        env: envS3MapItem.env,
                                        status: envS3MapItem.status,
                                        pipelineExecutionId: item.pipelineExecutionId
                                    })
                                }
                            })

                            return envExecutionIdMap.length === envS3Map.length;
                        });

                        turnPageNumber++;

                    } while (envExecutionIdMap.length !== envS3Map.length && nextTokenForListActionExecutions && turnPageNumber < 5)
                }

                setEnvExecutionIdMap(envExecutionIdMap);
            } catch (err) {
                if (!handleAwsAuthErr(err)) {
                    dispatch({
                        type: SHOW_TOAST,
                        payload: {
                            message: err.message,
                            severity: 'error'
                        }
                    });
                }
            }
        }
        getEnvInfos();

    }, [storeGetters.readonlyKeys, deployConfigMap, projectName, pipetype, actionExecutionList, loadTrigger, handleAwsAuthErr, dispatch, getCodeDeployParams, setEnvExecutionIdMap]);    
}

export default function useDeployEnv(projectName, pipetype, pipelineOwner, pipelineStages, isDataLakeAccount, actionExecutionList, needLoadtrigger) {
    const [envExecutionIdMap, setEnvExecutionIdMap] = useState(null);
    const [envVersionMap, setEnvVersionMap] = useState(null);
    const getAssumedKeys = useGetAssumedKeysForDeployAccounts();
    
    const [loadTrigger, setLoadTrigger] = useState(true);
    const codeDeployParams = useRef({});

    const getCodeDeployParams = useCallback(async (env) => {
        const currentParams = codeDeployParams.current[pipelineOwner]?.[env];
        if (currentParams) {
            if (currentParams === 'ERROR') {
                return null;
            }
            return currentParams;
        }

        if (!codeDeployParams.current[pipelineOwner]) {
            codeDeployParams.current[pipelineOwner] = {};
        }
        try {
            const params = await getAssumedKeys(pipelineOwner, env, 'r');
            codeDeployParams.current[pipelineOwner][env] = params;
            return params;
        } catch (err) {
            codeDeployParams.current[pipelineOwner][env] = 'ERROR';
            return null;
        }
    }, [pipelineOwner, getAssumedKeys]);

    useEffect(() => {
        if (needLoadtrigger) {
            const interval = setInterval(() => {
                setLoadTrigger((flag) => !flag);
            }, 15000);

            return () => {
                clearInterval(interval);
            }
        }
    }, [needLoadtrigger]);

    useEC2Env(projectName, pipetype, pipelineStages, actionExecutionList, loadTrigger, getCodeDeployParams, setEnvExecutionIdMap);
    useLambdaEnv(projectName, pipetype, pipelineStages, actionExecutionList, isDataLakeAccount, loadTrigger, getCodeDeployParams, setEnvVersionMap);

    return {envExecutionIdMap, envVersionMap};
}