import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState, useMemo, useRef } from "react";
import VulnerabilityDetailPopover from "./VulnerabilityDetailPopover";
import { PieChart, Cell, Legend, ResponsiveContainer, Pie } from "recharts";
import { CustomizedLegend } from "../Costreport/CostGraph";
import { COLOR_MAP } from "./index";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#FFFFFF",
    padding: "8px 24px",
    fontFamily: "Roboto",
  },
  graphContainer: {
    marginTop: "12px",
    height: "calc(100vh - 300px);",

    "& .recharts-pie-sector .recharts-sector": {
      cursor: "pointer",
    },
    "& .recharts-pie-sector .recharts-sector:focus": {
      outline: "none",
    },
  },
  loadingContainer: {
    textAlign: "center",
    paddingTop: "40px",
  },
  noDataContainer: {
    textAlign: "center",
    paddingTop: "40px",
  },
  loading: {
    color: "#E66400",
    marginLeft: "6px",
    position: "relative",
    top: "1px",
  },
}));

const labelRender = (obj) => {
  return (
    <text
      cx={obj.cx}
      cy={obj.cy}
      name={obj.name}
      color={obj.color}
      strokeWidth={obj.strokeWidth}
      alignmentBaseline={obj.alignmentBaseline}
      x={obj.x}
      y={obj.y}
      fontWeight="600"
      className="recharts-text recharts-pie-label-text"
      textAnchor={obj.textAnchor}
      fill="#4C4948"
    >
      <tspan x={obj.x} dy="0em">
        {obj.name}: {obj.value}
      </tspan>
    </text>
  );
};
const VulnerabilityGraph = ({ loading, data }) => {
  const classes = useStyles();
  const [detailOpen, setDetailOpen] = useState(false);
  const graphContainer = useRef();
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [popoverData, setPopoverData] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(null);

  const graphData = useMemo(() => {
    const result = {
      Critical: 0,
      High: 0,
      Medium: 0,
      Low: 0,
    };

    data.forEach((item) => {
      Object.entries(item.summary).forEach(([key, val]) => {
        result[key] = result[key] + val;
      });
    });

    return Object.entries(result).map((item) => {
      return {
        name: item[0],
        value: item[1],
        color: COLOR_MAP[item[0]],
      };
    }).filter(item => item.value > 0);
  }, [data]);

  const hasData = !!graphData.find((item) => {
    return item.value > 0;
  });

  const onPieClick = (event) => {
    setPopoverData(data.filter((item) => {
      return item.summary[event.name] > 0
    }));
    setCurrentLevel(event.name);
    setAnchorPosition({
      top: event.tooltipPosition.y,
      left: event.tooltipPosition.x,
    });
    setDetailOpen(true);
  };

  return (
    <Box className={classes.container}>
      <Box ref={graphContainer} className={classes.graphContainer}>
        {loading ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress className={classes.loading} size={32} />
          </Box>
        ) : hasData ? (
          <>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={500} height={300}>
                <Pie
                  startAngle={90}
                  endAngle={-270}
                  innerRadius={0.01}
                  onClick={onPieClick}
                  data={graphData}
                  labelLine={{ strokeWidth: 3 }}
                  label={labelRender}
                  dataKey="value"
                  nameKey="name"
                  fill="#8884d8"
                >
                  {graphData.map((item) => (
                    <Cell
                      key={`cell-${item.name}`}
                      fill={item.color}
                      stroke="#ffffff"
                      strokeWidth={7}
                    />
                  ))}
                </Pie>
                <Legend
                  verticalAlign="bottom"
                  iconType="circle"
                  content={<CustomizedLegend />}
                  wrapperStyle={{ paddingBottom: "20px" }}
                />
              </PieChart>
            </ResponsiveContainer>
            <VulnerabilityDetailPopover
              open={detailOpen}
              level={currentLevel}
              anchorPosition={anchorPosition}
              data={popoverData}
              onClose={() => setDetailOpen(false)}
            />
          </>
        ) : (
          <Box className={classes.noDataContainer}>
            <Typography>
              Congratulations! No errors were found under the current query
              conditions.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VulnerabilityGraph;
