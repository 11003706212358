import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import { AutoSizer, MultiGrid } from 'react-virtualized';
import searchIcon from '../../assets/icons/search.svg';
import totalTableIcon from '../../assets/icons/vulnerability_total_table.svg';
import { COLOR_MAP, TEAM_COLOR_MAP } from '.';

const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: 'Roboto'
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: '40px'
    },
    loading: {
        color: '#E66400',
        marginLeft: '6px',
        position: 'relative',
        top: '1px'
    },
    title: {
        padding: "36px 28px 22px",
        color: "#454545",
        fontWeight: 600,
        fontSize: '22px',
        letterSpacing: '-0.05px'
    },
    searchContainer: {
        background: '#ECECEC',
        padding: '14px 36px',
        position: 'relative'
    },
    searchIcon: {
        height: "16px",
        width: "16px",
        position: "absolute",
        pointerEvents: "none",
        top: '25px',
        left: '54px'
    },
    search: {
        width: '560px',
        height: '35px',
        borderRadius: '22px',
        border: '1px solid #4C4948',
        fontSize: '14px',
        paddingLeft: '40px',
        '&:focus': {
            outline: 'none'
        },
        '&::placeholder': {
            color: '#cccccc'
        }
    },
    th: {
        padding: '3px 0',
        fontWeight: 400,
        background: '#ECECEC',
        fontSize: '13px',
        color: '#4C4948'
    },
    thContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        borderRight: '0.5px solid #cccccc',
        alignItems: 'center',
        paddingLeft: '22px'
    },
    thContentNoBorder: {
        borderRight: 'none',
    },
    firstColumn: {
        paddingLeft: '38px'
    },
    td: {
        padding: '3px 0',
        fontWeight: 400,
        background: '#ffffff',
        fontSize: '13px',
        color: '#4C4948',
        boxSizing: 'border-box',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '0.5px solid #cccccc',
    },
    firstColumnTd: {
        paddingLeft: '18px'
    },
    colorDot: {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        position: 'absolute',
        left: '11px',
        top: '21px',
    },
    titleColorDot: {
        width: '12px',
        height: '12px',
        display: 'inline-block',
        borderRadius: '50%',
        marginRight: '5px',
        position: 'relative',
    },
    titleTotalColorDot: {
        width: '16px',
        height: '16px',
        display: 'inline-block',
        marginRight: '4px',
        position: 'relative',
    },
    totalDotIcon: {
        width: '13px',
        height: '13px',
        position: 'absolute',
        left: '10px',
        top: '19px',
    },
    componentlink: {
        color: '#AC4B00',
        textDecorationLine: 'underline',
    },
    tdContent: {
        paddingLeft: '20px',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
    },
    tdContentRightBorder: {
        borderRight: '0.5px solid #cccccc',
    },
    tdTxt: {}
}));

const COLUMNS = [
    {
        name: 'Owner',
        key: 'owner',
    },
    {
        name: 'Component',
        key: 'component_name',
    },
    {
        name: 'Total issue',
        key: 'Total',
    },
    {
        name: 'Critical',
        key: 'Critical',
    },
    {
        name: 'High',
        key: 'High',
    },
    {
        name: 'Medium',
        key: 'Medium',
    },
    {
        name: 'Low',
        key: 'Low',
    }
];

const VulnerabilityTable = (props) => {
    const classes = useStyles();
    const { data, loading } = props;

    const [searchTxt, setSearchTxt] = useState("");

    const calculateTableData = useMemo(() => {
        let result = data.map(item => {
            return {
                owner: item.owner,
                component_name: item.component_name,
                Total: item.summary.Critical + item.summary.High + item.summary.Low + item.summary.Medium,
                Critical: item.summary.Critical,
                High: item.summary.High,
                Medium: item.summary.Medium,
                Low: item.summary.Low,
            };
        })
        if (searchTxt.trim() !== '') {
            result = result.filter(item => {
                return item.owner.toLowerCase().includes(searchTxt.toLowerCase()) || item.component_name.toLowerCase().includes(searchTxt.toLowerCase());
            });
        }

        const totalRecord = result.reduce((total, item) => {
            total.Total = item.Total + total.Total;
            total.Critical = item.Critical + total.Critical;
            total.Low = item.Low + total.Low;
            total.Medium = item.Medium + total.Medium;
            total.High = item.High + total.High;
            return total;
        }, {
            owner: 'All Owners',
            component_name: 'All Components',
            Total: 0,
            Critical: 0,
            Low: 0,
            High: 0,
            Medium: 0
        });

        return [
            totalRecord,
            ...result.sort((a, b) => b.Total - a.Total)
        ]
    }, [data, searchTxt]);

    const cellRenderer = useCallback(({ columnIndex, key, rowIndex, style }) => {
        if (rowIndex === 0) {
            let text = COLUMNS[columnIndex].name;
            let color = COLOR_MAP[COLUMNS[columnIndex].key];
            if (COLUMNS[columnIndex].key === 'Total') {
                color = 'total';
            }
            let contentCss = classes.thContent;
            if (columnIndex === COLUMNS.length - 1) {
                contentCss += ` ${classes.thContentNoBorder}`;
            } else if (columnIndex === 0) {
                contentCss += ` ${classes.firstColumn}`;
            }
            return <div key={key} style={style} className={classes.th}>
                <div className={contentCss}>
                    {color === 'total' && <img src={totalTableIcon} alt='total icon' className={classes.titleTotalColorDot} />}
                    {color && color !== 'total' && <div className={classes.titleColorDot} style={{background: color}}></div>}
                    <span>{text}</span>
                </div>
            </div>
        }

        const txt = calculateTableData[rowIndex - 1][COLUMNS[columnIndex].key];
        let color = undefined;

        let fontWeight = 400;
        if (rowIndex === 1) {
            fontWeight = 600;
        }

        if (columnIndex === 0) {
            color = txt === 'Total issue' ? 'total' : TEAM_COLOR_MAP[txt];
        }

        return <div key={key} style={style} className={`${classes.td}${columnIndex === 0?` ${classes.firstColumnTd}`: ''}`}>
            {color === 'total' && <img src={totalTableIcon} alt='total icon' className={classes.totalDotIcon} />}
            {color && color !== 'total' && <div className={classes.colorDot} style={{background: color}}></div>}
            <div className={classes.tdContent} title={txt} style={{fontWeight}}>
                {
                    (columnIndex === 1 && rowIndex !== 1) ? <Link className={classes.componentlink} to={`/project/${txt}`}>{txt}</Link> : txt
                }
            </div>
        </div>;

    }, [calculateTableData, classes]);

    return <Box>
        <Typography className={classes.title}>Number of Vulnerability Issues’ Breakdown</Typography>
        <Box className={classes.searchContainer}>
            <input className={classes.search} placeholder='Search vulnerability issue breakdown' onChange={(e) => setSearchTxt(e.target.value)} />
            <img src={searchIcon} alt='search icon' className={classes.searchIcon} />
        </Box>
        <Box className={classes.container}>
            {
                loading ? <Box className={classes.loadingContainer}>
                    <CircularProgress className={classes.loading} size={32} />
                </Box> : <AutoSizer disableHeight>
                    {({ width }) => (
                        <MultiGrid
                            fixedRowCount={1}
                            cellRenderer={cellRenderer}
                            columnCount={COLUMNS.length}
                            fixedColumnCount={COLUMNS.length}
                            enableFixedColumnScroll
                            enableFixedRowScroll
                            height={562}
                            rowHeight={({index}) => index === 0 ? 62 : 50}
                            columnWidth={({index}) => width / 8 * (index === 1 ? 2 : 1)}
                            rowCount={calculateTableData.length + 1}
                            key={width}
                            width={width}
                            hideTopRightGridScrollbar
                            hideBottomLeftGridScrollbar
                        />
                    )}
                </AutoSizer>
            }

        </Box>
    </Box>

}

export default VulnerabilityTable;