import React, { useMemo } from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";

const StyledCommonButton = styled(Button)({
    boxShadow: 'none',
    fontSize: 14,
    textTransform: 'none',
    fontWeight: 600,
    border: '1px solid #E66400',
    backgroundColor: '#F6F6F6',
    paddingLeft: '28px',
    paddingRight: '28px',
    color: '#E66400',
    '&:hover': {
        backgroundColor: '#ECECEC',
        borderColor: '#E66400',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#F6F6F6',
        borderColor: '#E66400',
    },
    '&:focus': {
        backgroundColor: '#F6F6F6',
        borderColor: '#E66400',
    },
    '&.Mui-disabled': {
        color: '#CCCCCC',
        background: '#ECECEC',
        borderColor: '#ECECEC',
    },
    '& .MuiButton-label': {
        alignItems: 'center'
    }
});



const CommonButton2 = (props) => {

    const disabled = useMemo(() => {
        if (props.loading) return true;
        return props.disabled
        
    }, [props.loading, props.disabled])

    const startIcon = useMemo(() => {
        if (props.loading) return <CircularProgress size={18} color='inherit'/>
        return props.startIcon;
    }, [props.loading, props.startIcon]);
    
    return <StyledCommonButton {...props} loading={undefined} disabled={disabled} startIcon={startIcon}>
        {props.children}
    </StyledCommonButton>;
};

export default CommonButton2;
