import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AWS from "aws-sdk";

import { cb2Promise, getLogGroupIdentifier, useHandleAwsAuthErr } from '../../common/AwsUtil';
import { CloudWatchLogsClient, StartLiveTailCommand } from "@aws-sdk/client-cloudwatch-logs";
import { useDispatchReducerContext, useStateGettersContext } from '../../store';
import { SHOW_TOAST } from '../../store/dispatchNames';
import closePic from '../../assets/icons/close.png';
import { makeStyles } from "@material-ui/core/styles";
import { CommonDialog, CommonDialogContent } from '../../components/ui/CommonDialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';


const useLogDialogClasses = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: '1180px',
            maxWidth: '1180px'
        }
    },
    dialogContent: {
        paddingBottom: '32px'
    },
    title: {
        color: '#4C4948',
        fontSize: '15px',
        fontWeight: 400,
        fontFamily: 'Roboto',
        lineHeight: '20px',
        paddingTop: '18px'
    },
    closeicon: {
        position: 'absolute',
        right: '18px',
        top: '20px',
        cursor: 'pointer',
        width: '20px'
    },
    logContent: {
        background: '#000000',
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '18px',
        padding: '16px',
        marginTop: '6px',
        maxHeight: '400px',
        overflow: 'auto'
    },
    seqNumber: {
        color: '#11A38B !important'
    },
    loading: {
        color: '#D8B200',
        width: '15px !important',
        height: '15px !important',
        position: 'relative',
        top: '3px',
        left: '8px'
    },
    error: {
        color: '#ff6464',
    },
    info: {
        color: '#fff',
    },
    warn: {
        color: '#ff7f32',
    },
    success: {
        color: '#11d420',
    },
}))

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


const LogDialog = (props) => {
    const classes = useLogDialogClasses();
    const logContent = useRef();
    const prevLogData = usePrevious(props.logData);

    useEffect(() => {
        if (logContent.current && props.logData.length > 0 && prevLogData.length !== props.logData.length) {
            logContent.current.scrollTop = logContent.current.scrollHeight;
        }
    }, [props.logData, prevLogData])

    const logClass = useCallback((log) => {
        const str = log.message.toLowerCase();
        return (str.includes('err') || str.includes('fail')) ? classes.error : str.includes('warn') ? classes.warn : str.includes('succ') ? classes.success : classes.info;
    }, [classes]);

    return <CommonDialog
        open={props.open}
        onClose={props.onClose}
        className={classes.container}
    >
        <CommonDialogContent className={classes.dialogContent}>
            <Box className={classes.title} >Log:{props.loading && <CircularProgress className={classes.loading} />}</Box>
            <img alt='close' className={classes.closeicon} src={closePic} onClick={props.onClose} />

            <Box className={classes.logContent} ref={logContent}>
                {props.logData.map((log, index) => <Typography key={index} className={logClass(log)}>
                    <span className={classes.seqNumber}>{moment(log.timestamp).format('YYYY-MM-DD HH:mm:ss')}: </span>{log.message}
                </Typography>)}
            </Box>
        </CommonDialogContent>
    </CommonDialog>
}




const useLog = (actionList) => {
    const storeGetters = useStateGettersContext();
    const dispatch = useDispatchReducerContext();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const [logData, setLogData] = useState([]);
    const [isShowLog, setIsShowLog] = useState(false);
    const [action, setAction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const liveLogClient = useRef(null);
    const liveLogAbortController = useRef(null);
    const isShowLogRef = useRef(false);

    const actionListWithLogStatus = useMemo(() => {
        return actionList.map(action => {
            const disabledLog = !action?.input?.configuration?.ProjectName;
            return {
                ...action,
                disabledLog
            }
        })
    }, [actionList])

    const handleShowLogClick = (action) => {
        setAction(action);
        setLogData([]);
        setIsShowLog(true);
        isShowLogRef.current = true;
    }

    const onLogClose = () => {
        setIsShowLog(false);
        isShowLogRef.current = false;
    }

    const handleResponseAsync = useCallback(async (response) => {
        for await (const event of response.responseStream) {
            if (event.sessionStart !== undefined) {
                console.log(event.sessionStart);
            } else if (event.sessionUpdate !== undefined) {
                console.log('livelog', event.sessionUpdate.sessionResults);
                setLogData((logData) => [...logData, ...event.sessionUpdate.sessionResults]);
            } else {
                console.error("Unknown event type");
            }
        }
    }, []);

    const startLiveLog = useCallback(async (logGroupName, logStreamName) => {
        liveLogClient.current = new CloudWatchLogsClient({
            credentials: storeGetters.readonlyKeys,
            region: storeGetters.readonlyKeys.region
        });

        liveLogAbortController.current = new AbortController();

        const command = new StartLiveTailCommand({
            logGroupIdentifiers: [getLogGroupIdentifier(logGroupName)],
            logStreamNames: [logStreamName]
        });
        const response = await liveLogClient.current.send(command, {
            abortSignal: liveLogAbortController.current.signal
        });

        handleResponseAsync(response);

    }, [storeGetters.readonlyKeys, handleResponseAsync]);

    const logGroupNamePlusSteam = useMemo(() => {
        return action?.output?.executionResult?.externalExecutionId;
    }, [action])

    const logProjectName = useMemo(() => {
        return action?.input?.configuration?.ProjectName;
    }, [action])

    const destroyLiveLogClient = useCallback(() => {
        try {
            if (liveLogAbortController.current && !liveLogAbortController.current?.signal.aborted) {
                liveLogAbortController.current.abort();
            }
            liveLogAbortController.current = null;
            liveLogClient.current?.destroy();
            liveLogClient.current = null;
        } catch (error) {
            console.log(JSON.stringify(error));
        }
        
    }, [])

    useEffect(() => {
        if (!isShowLog) return;
        const cloudWatchLogs = new AWS.CloudWatchLogs(storeGetters.readonlyKeys);

        // Completed Log
        if (logGroupNamePlusSteam) {
            setIsLoading(true);
            const [logGroupName, streamName] = logGroupNamePlusSteam.split(":");

            cb2Promise(cloudWatchLogs, 'getLogEvents', {
                logGroupName: "/aws/codebuild/" + logGroupName,
                logStreamName: streamName,
                limit: 2000
            }).then(data => {
                setLogData(data.events);
            }).catch((err) => {
                if (!handleAwsAuthErr(err)) {
                    dispatch({
                        type: SHOW_TOAST,
                        payload: {
                            message: err.message,
                            severity: 'error'
                        }
                    });
                }
            }).finally(() => {
                setIsLoading(false);
            })
        } else {
            // Live Log
            setIsLoading(true);
            const codeBuild = new AWS.CodeBuild(storeGetters.readonlyKeys);
            cb2Promise(codeBuild, 'listBuildsForProject', {
                projectName: logProjectName
            }).then((data) => {
                if (!data || !isShowLogRef.current) return;
                return cb2Promise(codeBuild, 'batchGetBuilds', {
                    ids: data.ids
                });
            }).then(data => {
                if (!data || !isShowLogRef.current) return;
                const liveLogBuild = data.builds.find((item) => item.buildStatus === 'IN_PROGRESS')
                if (!liveLogBuild?.logs?.streamName) return;
                cb2Promise(cloudWatchLogs, 'getLogEvents', {
                    logGroupName: liveLogBuild.logs.groupName,
                    logStreamName: liveLogBuild.logs.streamName,
                    limit: 2000
                }).then(data => {
                    if (!data || !isShowLogRef.current) return;
                    setLogData(data.events);
                }).catch((err) => {
                    if (!handleAwsAuthErr(err)) {
                        dispatch({
                            type: SHOW_TOAST,
                            payload: {
                                message: err.message,
                                severity: 'error'
                            }
                        });
                    }
                }).finally(() => {
                    setIsLoading(false);
                });
                return startLiveLog(liveLogBuild.logs.groupName, liveLogBuild.logs.streamName);
            }).catch((err) => {
                if (!handleAwsAuthErr(err)) {
                    dispatch({
                        type: SHOW_TOAST,
                        payload: {
                            message: err.message,
                            severity: 'error'
                        }
                    });
                }
            });

            return () => destroyLiveLogClient();
        }
    }, [storeGetters.readonlyKeys, isShowLog, logGroupNamePlusSteam, logProjectName, handleAwsAuthErr, dispatch, startLiveLog, destroyLiveLogClient]);

    const logComp = <LogDialog
        open={isShowLog}
        loading={isLoading}
        logData={logData}
        onClose={onLogClose}
    />;

    return {
        handleShowLogClick,
        logComp,
        actionListWithLogStatus
    }
}

export default useLog;