import { useEffect, useMemo, useState } from "react";
import AWS from "aws-sdk";
import ConfirmDialog from "../../components/ui/ConfirmDialog";
import redeployWhiteIcon from '../../assets/icons/redeploy_white.svg';
import { makeStyles } from "@material-ui/core";
import { useDispatchReducerContext } from "../../store";
import { DISMISS_APP_LOADING, SHOW_APP_LOADING, SHOW_TOAST } from "../../store/dispatchNames";
import { cb2Promise, useGetAssumedKeysForDeployAccounts, useHandleAwsAuthErr } from "../../common/AwsUtil";

const useRedeployConfirmClasses = makeStyles((theme) => ({
    redeployActionIcon: {
        width: '20px',
        height: '20px',
        position: 'relative',
        top: '-1px'
    },
}))

export default function useRedeploy(projectName, owner, actionList, pipelineType, isDatalakeAccount, envExecutionIdMap, version, envVersionMap, onRefreshRequest) {
    const classes = useRedeployConfirmClasses();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const [isShowConfirmRedeploy, setIsShowConfirmRedeploy] = useState(false);
    const dispatch = useDispatchReducerContext();
    const getAssumedKeys = useGetAssumedKeysForDeployAccounts();
    const [action, setAction] = useState(null);
    const [lambdaVersionMap, setLambdaVersionMap] = useState({});

    const actionLen = useMemo(() => {
        return actionList.length;
    }, [actionList]);

    useEffect(() => {
        if (pipelineType.toLowerCase() !== 'lambda') {
            setLambdaVersionMap({});
            return;
        }

        const getLambdaVersion = async () => {
            const deployActions = isDatalakeAccount ? ['DEPLOY-IN-DATALAKE-QA', 'DEPLOY-IN-DATALAKE-PROD'] : ['DEPLOY-IN-PROD', 'DEPLOY-IN-QA', 'DEPLOY-IN-TEST'];
            const versionInfos = await Promise.all(deployActions.map(action => {
                const fn = async () => {
                    try {
                        let env = null;
                        switch (action) {
                            case "DEPLOY-IN-TEST":
                                env = 'test'
                                break;
                            case "DEPLOY-IN-QA":
                                env = 'qa'
                                break;
                            case "DEPLOY-IN-PROD":
                                env = 'prod'
                                break;
                            case "DEPLOY-IN-DATALAKE-QA":
                                env = 'datalake-qa'
                                break;
                            case "DEPLOY-IN-DATALAKE-PROD":
                                env = 'datalake-prod'
                                break;
                        
                            default:
                                break;
                        }
                        const params = await getAssumedKeys(owner, env, 'r');
                        const lambda = new AWS.Lambda(params);
                        let rollbackVersion = null
                        let nextMarker = null;
                        do {
                            const {NextMarker, Versions} = await cb2Promise(lambda, "listVersionsByFunction", {
                                FunctionName: projectName,
                                Marker: nextMarker,
                                MaxItems: 50
                            });
                            nextMarker = NextMarker;
                            const versionItem = Versions.find(item => item.Version !== '$LATEST' && item.Environment.Variables.PIPELINE_VERSION === version)
                            rollbackVersion = versionItem?.Version;
                        } while (!rollbackVersion && nextMarker)
    
                        return {
                            actionName: action,
                            rollbackVersion,
                        }
                    } catch (err) {
                        if (!handleAwsAuthErr(err)) {
                            return null;
                        }
                    }
                }

                return fn();
            }));

            setLambdaVersionMap(versionInfos.reduce((total, item) => {
                if (item && item.rollbackVersion) {
                    total[item.actionName] = item.rollbackVersion;
                }
                return total;
            }, {}));
        }
        getLambdaVersion();
    }, [pipelineType, actionLen, version, getAssumedKeys, handleAwsAuthErr, owner, projectName, isDatalakeAccount]);

    const actionListWithRedeployStatus = useMemo(() => {
        switch (pipelineType.toLowerCase()) {
            case 'application':
                return actionList.map((action) => {
                    const envExecutionIdItem = envExecutionIdMap?.find(item => item.env === action.actionName);
                    return {
                        ...action,
                        enabledRedeploy: envExecutionIdMap &&
                            ['DEPLOY-IN-PROD', 'DEPLOY-IN-QA', 'DEPLOY-IN-TEST'].includes(action.actionName) && 
                            !envExecutionIdItem
                    };
                });
            
            case 'lambda':
                return actionList.map((action) => {
                    const envExecutionIdItem = envVersionMap?.find(item => item.env === action.actionName);

                    return {
                        ...action,
                        enabledRedeploy: envVersionMap &&
                            lambdaVersionMap[action.actionName] && 
                            envExecutionIdItem &&
                            envExecutionIdItem.status === 'Succeeded' &&
                            envExecutionIdItem.pipelineVersion !== version
                    };
                });
        
            default:
                return actionList;
        }
    }, [actionList, pipelineType, envExecutionIdMap, version, envVersionMap, lambdaVersionMap]);

    const handleRedeployClick = (action) => {
        setIsShowConfirmRedeploy(true);
        setAction(action);
    }

    const redeployProject = async () => {
        let env = null;
        switch (action.actionName) {
            case "DEPLOY-IN-TEST":
                env = 'test'
                break;
            case "DEPLOY-IN-QA":
                env = 'qa'
                break;
            case "DEPLOY-IN-PROD":
                env = 'prod'
                break;
            case "DEPLOY-IN-DATALAKE-QA":
                env = 'datalake-qa'
                break;
            case "DEPLOY-IN-DATALAKE-PROD":
                env = 'datalake-prod'
                break;
        
            default:
                break;
        }
        dispatch({
            type: SHOW_APP_LOADING
        })
        try {
            const params = await getAssumedKeys(owner, env, 'w');

            switch (pipelineType.toLowerCase()) {
                case 'application':
                    const codedeploy = new AWS.CodeDeploy(params);
                    await cb2Promise(codedeploy, "createDeployment", {
                        applicationName: action.input.configuration.ApplicationName,
                        deploymentGroupName: action.input.configuration.DeploymentGroupName,
                        revision: {
                            revisionType: 'S3',
                            s3Location: {
                                ...action.input.inputArtifacts[0].s3location,
                                bundleType: 'zip'
                            }
                        }

                    });
                    break;

                case 'lambda':
                    const lambda = new AWS.Lambda(params);

                    const rollbackVersion = lambdaVersionMap[action.actionName];

                    if (!rollbackVersion) {
                        dispatch({
                            type: SHOW_TOAST,
                            payload: {
                                message: 'Not able to rollback, please check if the version exisiting in AWS lambda.',
                                severity: 'error'
                            }
                        });
                        return;
                    }

                    await cb2Promise(lambda, "updateAlias", {
                        FunctionName: projectName,
                        Name: 'live',
                        FunctionVersion: rollbackVersion
                    });

                    break;

                default:
                    break;
            }
            
            setIsShowConfirmRedeploy(false);

            dispatch({
                type: SHOW_TOAST,
                payload: {
                    message: 'Project redeployment started successfully',
                    severity: 'success'
                }
            });
            setTimeout(() => {
                onRefreshRequest();
            }, 2000);
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
            }
        } finally {
            dispatch({
                type: DISMISS_APP_LOADING
            })
        }
    }

    const redeployComp = <ConfirmDialog
        open={isShowConfirmRedeploy}
        onClose={() => setIsShowConfirmRedeploy(false)}
        title={`Are you sure you want to redeploy this version?`}
        onConfirm={redeployProject}
        confirmText="Redeploy"
        icon={<img className={classes.redeployActionIcon} src={redeployWhiteIcon} alt='trigger' />}
    />

    return {
        handleRedeployClick,
        actionListWithRedeployStatus,
        redeployComp
    }
}