import { useCallback } from 'react';
import { useDispatchReducerContext, useStateReducerContext } from '../store';
import { CLEAR_ACCESSINFO, SET_ASSUMEROLE_PARAM_CACHE, SHOW_TOAST } from '../store/dispatchNames';

import AWS from "aws-sdk";

export const ACCOUNT_MAP = {
    BUILD: {
        name: 'ud-aws-build',
        id: '372158068517'
    },
    TEST: {
        name: 'ud-aws-test',
        id: '017107351875'
    },
    QA: {
        name: 'ud-aws-qa',
        id: '989815888499'
    },
    PROD: {
        name: 'ud-aws-prod',
        id: '491943201164'
    },
    DL_QA: {
        name: 'ud-aws-datalake-qa',
        id: '868472464340'
    },
    DL_PROD: {
        name: 'ud-aws-datalake-prod',
        id: '184121588438'
    }
}

export const COST_ACCOUNT = [
    ACCOUNT_MAP.BUILD,
    ACCOUNT_MAP.TEST,
    ACCOUNT_MAP.QA,
    ACCOUNT_MAP.PROD,
    ACCOUNT_MAP.DL_QA,
    ACCOUNT_MAP.DL_PROD,
];

export const COST_ROLENAME = 'cicd_cost_reference_role';

export const OWNER_LIST = [
    'ads',
    'cors',
    'ffs',
    'rms',
    'ims',
    'fw'
];

export const CICD_ACCOUNT_READONLY_ROLE = 'Cicd-Account-ReadOnly-Role';

export function useHandleAwsAuthErr() {
    const dispatch = useDispatchReducerContext();

    return useCallback((err) => {
        if (err.code === 'ExpiredToken' || err.code === 'ExpiredTokenException') {
            dispatch({
                type: SHOW_TOAST,
                payload: {
                    message: 'AWS token is expired, please re-login!',
                    severity: 'error'
                }
            });
            dispatch({ type: CLEAR_ACCESSINFO })
            return true;
        }
    }, [dispatch])
}

export function getLogGroupIdentifier(logGroupName) {
    return `arn:aws:logs:eu-west-1:${ACCOUNT_MAP.BUILD.id}:log-group:${logGroupName}`
}

export function useGetAssumedKeysForDeployAccounts() {
    const getAssumedKeysByAccountIdAndRole = useGetAssumedKeysByAccountIdAndRole();

    return useCallback((owner, env, wr) => {
        let account = "";
        switch (env) {
            case "prod":
                account = ACCOUNT_MAP.PROD.id;
                break;
            case "qa":
                account = ACCOUNT_MAP.QA.id;
                break;
            case "test":
                account = ACCOUNT_MAP.TEST.id;
                break;
            case "datalake-qa":
                account = ACCOUNT_MAP.DL_QA.id;
                break;
            case "datalake-prod":
                account = ACCOUNT_MAP.DL_PROD.id;
                break;
            case "preprod":
                account = "923613125418";
                break;

            default:
                throw new Error('env must be one of [test, qa, prod, dlqa, dlprod, preprod]');
        }

        // assum 'cors' if owner is 'saas'
        if (owner === 'saas') owner = 'cors';

        return getAssumedKeysByAccountIdAndRole(account, wr === 'r' ? owner : owner+"-devops");
    }, [getAssumedKeysByAccountIdAndRole])
}

export function useGetAssumedKeysForBuildAccount() {
    const getAssumedKeysByAccountIdAndRole = useGetAssumedKeysByAccountIdAndRole();

    return useCallback((owner, env) => {
        let accountId = ''
        let role = '';
        if (owner == null) {
            accountId = ACCOUNT_MAP.BUILD.id;
            role = CICD_ACCOUNT_READONLY_ROLE;
        } else {
            // assum 'cors' if owner is 'saas'
            if (owner === 'saas') owner = 'cors';
            if (env == null || env !== `prod`) {
                if (OWNER_LIST.includes(owner)) {
                    accountId = ACCOUNT_MAP.BUILD.id;
                    role = `${owner}-devops`;
                }
            } else if (env === "prod") {
                if (OWNER_LIST.includes(owner)) {
                    accountId = ACCOUNT_MAP.BUILD.id;
                    role = `${owner}-prod-devops`
                }
            }
        }

        return getAssumedKeysByAccountIdAndRole(accountId, role);
    }, [getAssumedKeysByAccountIdAndRole]);
}

// The method use for start execution/stop execution/create project
export function useGetAssumedKeysForBuildPureRole() {
    const getAssumedKeysByAccountIdAndRole = useGetAssumedKeysByAccountIdAndRole();

    return useCallback((owner, env) => {
        let accountId = ''
        let role = '';
        if (owner == null) {
            accountId = ACCOUNT_MAP.BUILD.id;
            role = CICD_ACCOUNT_READONLY_ROLE;
        } else {
            // assum 'cors' if owner is 'saas'
            if (owner === 'saas') owner = 'cors';

            if (OWNER_LIST.includes(owner)) {
                accountId = ACCOUNT_MAP.BUILD.id;
                role = owner;
            }
        }

        return getAssumedKeysByAccountIdAndRole(accountId, role);
    }, [getAssumedKeysByAccountIdAndRole]);
}

export function useGetAssumedKeysByAccountIdAndRole() {
    const storeState = useStateReducerContext();
    const dispatch = useDispatchReducerContext();

    return useCallback((accountId, role) => {
        if (storeState.assumeRoleParamCache?.[accountId]?.[role]) {
            return storeState.assumeRoleParamCache[accountId][role];
        }
        const options = {
            apiVersion: "2011-06-15",
            accessKeyId: storeState.mfaKeys.mfaAccessKeyId,
            secretAccessKey: storeState.mfaKeys.mfaSecretAccessKey,
            sessionToken: storeState.mfaKeys.mfaSessionId
        };
        const sts = new AWS.STS(options);

        if (!accountId || !role) {
            throw new Error('accountId & role are required');
        }

        return new Promise((resolve, reject) => {
            const timestamp = (new Date()).getTime();
            const params = {
                RoleArn: `arn:aws:iam::${accountId}:role/${role}`,
                RoleSessionName: `session-for-assumed-role-${timestamp}`
            };
            sts.assumeRole(params, (err, data) => {
                if (err) {
                    reject(err);
                }
                else {
                    const param = {
                        accessKeyId: data.Credentials.AccessKeyId,
                        secretAccessKey: data.Credentials.SecretAccessKey,
                        sessionToken: data.Credentials.SessionToken,
                        region: "eu-west-1"
                    };
                    dispatch({
                        type: SET_ASSUMEROLE_PARAM_CACHE,
                        payload: {
                            param,
                            accountId,
                            role
                        }
                    })
                    resolve(param);
                }
            });
        });
    }, [storeState.mfaKeys, storeState.assumeRoleParamCache, dispatch]);
}

export function cb2Promise(target, fn, ...params) {
    return new Promise((resolve, reject) => {
        target[fn](...params, (err, data) => {
            if (err) {
                reject(err)
            } else {
                resolve(data);
            }
        })
    });
}