import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import BuildRender from './BuildRender';
import useApprove from './useApprove';
import useRetry from './useRetry';
import useLog from './useLog';
import useRedeploy from './useRedeploy';
import arrowPic from '../../assets/icons/arrow.png'
import { makeStyles, styled } from "@material-ui/core/styles";

const ParallelBox = styled(Box)({
    display: 'Flex',
    flexDirection: 'row',
    columnGap: '16px',
    justifyContent: 'space-between',
    alignItems: 'stretch'
})

const useStyles = makeStyles((theme) => ({
    arrow: {
        margin: '12px 0 12px 56px',
        width: '24px',
        height: 'auto'
    }
}));

const BuildStage = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;
    const classes = useStyles();

    const buildActionList = useMemo(() => {
        return actionList.filter((el) => (el.stageName === "BUILD" || el.stageName === "Build") && ['BUILD', 'Build'].includes(el.actionName));
    }, [actionList]);

    const sastActionList = useMemo(() => {
        return actionList.filter((el) => (el.stageName === "BUILD" || el.stageName === "Build") && ["SAST", "SAST-Scan", "SAST-SCAN"].includes(el.actionName));
    }, [actionList]);

    const isBuildOnly = useMemo(() => {
        return buildActionList.length > 0 && sastActionList.length === 0;
    }, [buildActionList, sastActionList]);

    const isSastOnly = useMemo(() => {
        return sastActionList.length > 0 && buildActionList.length === 0;
    }, [buildActionList, sastActionList]);

    return <>
        {
            isBuildOnly && <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={buildActionList} hasArraw={true} title="Build" />
        }
        {
            isSastOnly && <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sastActionList} hasArraw={true} title="Scan" />
        }
        {
            !isBuildOnly && !isSastOnly && buildActionList.length > 0 && sastActionList.length > 0 &&
            <>
            <ParallelBox>
                <BuildRender isHalfSize={true} handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={buildActionList} hasArraw={false} title="Build" />
                <BuildRender isHalfSize={true} handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sastActionList} hasArraw={false} title="Scan" />
            </ParallelBox>
            <img className={classes.arrow} src={arrowPic} alt="arrow" />
            </>
        }
    </>
}


const InfraBuild = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const prodEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-PROD"));
    const qaEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-QA"));
    const testEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-TEST"));
    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE"));

    return <Box>
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={prodEnvActionList} hasArraw={true} title="Prod" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={qaEnvActionList} hasArraw={true} title="QA" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={testEnvActionList} hasArraw={true} title="Test" />
        <BuildStage {...props} />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}

const LibBuild = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE" || el.stageName === "Source"));

    return <Box>
        <BuildStage {...props} />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}



const AppBuild = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const prodEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-PROD"));
    const qaEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-QA"));
    const testEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-TEST" || el.stageName === "Deploy"));
    const compTestActionList = actionList.filter((el) => (el.stageName === "COMPONENT-TEST" || el.stageName === "Test"));
    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE" || el.stageName === "Source"));

    return <Box>
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={prodEnvActionList} hasArraw={true} title="Prod" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={qaEnvActionList} hasArraw={true} title="QA" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={testEnvActionList} hasArraw={true} title="Test" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={compTestActionList} hasArraw={true} title="Component Test" />
        <BuildStage {...props} />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}

const LambdaBuild = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const prodEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-PROD"));
    const qaEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-QA"));
    const testEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-TEST" || el.stageName === "Deploy"));
    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE" || el.stageName === "Source"));

    return <Box>
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={prodEnvActionList} hasArraw={true} title="Prod" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={qaEnvActionList} hasArraw={true} title="QA" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={testEnvActionList} hasArraw={true} title="Test" />
        <BuildStage {...props} />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}

const LambdaBuildForDL = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const prodEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-DATALAKE-PROD"));
    const qaEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-DATALAKE-QA"));
    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE" || el.stageName === "Source"));

    return <Box>
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={prodEnvActionList} hasArraw={true} title="Datalake Prod" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={qaEnvActionList} hasArraw={true} title="Datalake QA" />
        <BuildStage {...props} />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}

const AwsResourceBuild = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const prodEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-PROD"));
    const qaEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-QA"));
    const testEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-TEST" || el.stageName === "Deploy"));
    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE" || el.stageName === "Source"));

    return <Box>
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={prodEnvActionList} hasArraw={true} title="Prod" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={qaEnvActionList} hasArraw={true} title="QA" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={testEnvActionList} hasArraw={true} title="Test" />
        <BuildStage {...props} />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}

const AwsResourceBuildForDL = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const prodEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-DATALAKE-PROD"));
    const qaEnvActionList = actionList.filter((el) => (el.stageName === "DEPLOY-IN-DATALAKE-QA"));
    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE" || el.stageName === "Source"));

    return <Box>
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={prodEnvActionList} hasArraw={true} title="Datalake Prod" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={qaEnvActionList} hasArraw={true} title="Datalake QA" />
        <BuildStage {...props} />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}

const AuthBuild = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const prodEnvActionList = actionList.filter((el) => (el.stageName === "Deploy-In-Prod"));
    const qaEnvActionList = actionList.filter((el) => (el.stageName === "Deploy-In-QA"));
    const testEnvActionList = actionList.filter((el) => (el.stageName === "Deploy-In-Test"));
    const devEnvActionList = actionList.filter((el) => (el.stageName === "Deploy-In-Dev"));
    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE" || el.stageName === "Source"));

    return <Box>
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={prodEnvActionList} hasArraw={true} title="Prod" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={qaEnvActionList} hasArraw={true} title="QA" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={testEnvActionList} hasArraw={true} title="Test" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={devEnvActionList} hasArraw={true} title="Dev" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}
const LoadTestBuild = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const destroyActionList = actionList.filter((el) => (el.stageName === "DESTROY"));
    const loadTestActionList = actionList.filter((el) => (el.stageName === "LOAD-TEST"));
    const sourceActionList = actionList.filter((el) => (el.stageName === "SOURCE" || el.stageName === "Source"));

    return <Box>
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={destroyActionList} hasArraw={true} title="Destory Stack" />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={loadTestActionList} hasArraw={true} title="Load Test" />
        <BuildStage {...props} />
        <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} handleRedeploy={handleRedeploy} actions={sourceActionList} hasArraw={false} title="Source" />
    </Box>
}

const GeneralBuild = (props) => {
    const { actionList, handleApprove, handleRetry, handleShowLog, handleRedeploy } = props;

    const groupActionList = useMemo(() => {
        const group = [];
        actionList.forEach((item) => {
            const lastGroup = group[group.length - 1];
            if (lastGroup && lastGroup.title === item.stageName) {
                lastGroup.actions.push(item);
            } else {
                group.push({
                    title: item.stageName,
                    actions: [item]
                })
            }

        })
        return group;
    }, [actionList]);

    return <Box>
        {
            groupActionList.map((item, index) => {
                if (["BUILD", "Build"].includes(item.title)) return <BuildStage {...props} actions={item.actions} key={item.title} />;
                return <BuildRender handleApprove={handleApprove} handleRetry={handleRetry} handleShowLog={handleShowLog} actions={item.actions} hasArraw={index < groupActionList.length - 1} title={item.title} key={item.title} handleRedeploy={handleRedeploy} />
            })
        }
    </Box>
}

const ExecutionActionList = (props) => {
    const { pipelineType, actionList, datalakeAccount, pipelineOwner, projectName, pipelineExecutionId, version, envExecutionIdMap, envVersionMap, onRefreshRequest } = props;
    const { handleApproveClick, approveComp } = useApprove(pipelineOwner, projectName, onRefreshRequest);
    const { handleRetryClick, retryComp, actionListWithRetryStatus: populatedRetryActionList } = useRetry(actionList, pipelineOwner, projectName, pipelineExecutionId, onRefreshRequest);
    const { handleShowLogClick, logComp, actionListWithLogStatus: populatedLogStatusActionList } = useLog(populatedRetryActionList);
    const { handleRedeployClick, redeployComp, actionListWithRedeployStatus: populatedActionList } = useRedeploy(projectName, pipelineOwner, populatedLogStatusActionList, pipelineType, datalakeAccount, envExecutionIdMap, version, envVersionMap, onRefreshRequest);

    if (!populatedActionList) return <></>;

    let listComp = null;
    switch (pipelineType) {
        case "infrastructure":
            listComp = <InfraBuild actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            break;
        case "lib":
            listComp = <LibBuild actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            break;
        case "application":
            listComp = <AppBuild actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            break;
        case "lambda":
            if (datalakeAccount) listComp = <LambdaBuildForDL actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            else listComp = <LambdaBuild actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            break;
        case "aws-resource":
            if (datalakeAccount) listComp = <AwsResourceBuildForDL actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            else listComp = <AwsResourceBuild actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            break;
        case "auth0-management-pipeline":
            listComp = <AuthBuild actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            break;
        case "loadtest":
            listComp = <LoadTestBuild actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            break;
        default:
            listComp = <GeneralBuild actionList={populatedActionList} handleApprove={handleApproveClick} handleRetry={handleRetryClick} handleShowLog={handleShowLogClick} handleRedeploy={handleRedeployClick} />;
            break;
    }

    return <>
        {listComp}
        {approveComp}
        {retryComp}
        {logComp}
        {redeployComp}
    </>
}

export default ExecutionActionList;