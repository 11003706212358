import React, { useEffect, useMemo, useState } from 'react';

import { CommonDialog, CommonDialogContent } from "../../components/ui/CommonDialog";
import { Accordion, AccordionSummary, AccordionDetails, Box, makeStyles, Typography, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, Paper, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import closePic from '../../assets/icons/close.png';
import { useScanReportSummaryInfo } from './ScanReport';
import moment from 'moment';
import { COLOR_MAP } from '../Vulnerabilityreport';

const useClasses = makeStyles((theme) => ({
  container: {
    '& .MuiDialog-paper': {
      width: '1000px',
      maxWidth: 'none',
    }
  },
  dialogContent: {
    paddingBottom: '20px'
  },
  summaryWrapper: {
    display: 'grid',
    color: '#FFFFFF',
    gap: '1px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    '& div': {
      padding: '4px 10px',
    }
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    position: 'relative'
  },
  scanDate: {
    paddingLeft: '16px'
  },
  summaryItem: {
    background: '#4C4948',
  },
  noIssueItem: {
    background: '#11A38B !important',
  },
  criticalItem: {
    background: COLOR_MAP.Critical,
  },
  highItem: {
    background: COLOR_MAP.High,
  },
  mediumItem: {
    background: COLOR_MAP.Medium,
  },
  lowItem: {
    background: COLOR_MAP.Low,
  },
  titleItem: {
    marginTop: '12px'
  },
  closeicon: {
    position: 'absolute',
    right: '6px',
    top: '8px',
    cursor: 'pointer',
    width: '20px'
  },
  loadingWrapper: {
    textAlign: 'center',
    margin: '16px auto'
  },
  loading: {
    color: '#E66400',
  },
  errorMsg: {
    margin: '12px 0'
  },
  subTitle: {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  nodeTable: {
    '& .MuiTableCell-root': {
      wordBreak: 'break-all'
    }
  }
}));

const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#E66400',
  },
})(Tabs);

const StyledTab = withStyles({
  selected: {
    color: '#e66400 !important',
  }
})(Tab);

const TabPanel = (props) => {
  const { children, value, hidden, ...other } = props;

  return (
    <div
      {...other}
    >
      {!hidden && <div style={{ padding: '4px', minHeight: '250px' }}>{children}</div>}
    </div>
  );
}

const FortifyReport = (props) => {
  let { scanData } = props;

  if (!scanData) scanData = [];
  const summary = useScanReportSummaryInfo(scanData);

  const groupData = useMemo(() => {
    return Object.entries(scanData.reduce((total, item) => {
      const data = item.data;

      if (data) {
        if (total[data.friority]) {
          total[data.friority].push(item);
        } else {
          total[data.friority] = [item];
        }
      }
      return total;
    }, {})).sort((a, b) => {
      const orderTemplate = ['Critical', 'High', 'Medium', 'Low'];
      return orderTemplate.indexOf(a[0]) - orderTemplate.indexOf(b[0]);
    });
  }, [scanData]);

  const [groupValue, setGroupValue] = useState('Critical');

  useEffect(() => {
    if (groupData.length > 0) setGroupValue(groupData[0][0]);
  }, [groupData])

  const handleGroupChange = (event, newValue) => {
    setGroupValue(newValue);
  };

  const classes = useClasses();

  return <CommonDialog
    open={props.open}
    onClose={props.onClose}
    className={classes.container}
  >
    <CommonDialogContent className={classes.dialogContent}>
      <Box className={classes.titleWrapper}>
        <img alt='close' className={classes.closeicon} src={closePic} onClick={props.onClose} />
        <Typography variant="h5">
          Fortify Scan Report
        </Typography>
      </Box>
      <Typography className={classes.titleItem} variant="h6">
        Summary
      </Typography>
      <Box className={classes.summaryWrapper}>
        <Box className={`${classes.summaryItem} ${summary.totalIssues === 0 && classes.noIssueItem}`}>
          <Typography variant="subtitle1">
            Total
          </Typography>
          <Typography >
            {summary.totalIssues}
          </Typography>
        </Box>
        <Box className={`${classes.criticalItem} ${summary.criticalIssues === 0 && classes.noIssueItem}`}>
          <Typography variant="subtitle1">
            Critical
          </Typography>
          <Typography >
            {summary.criticalIssues}
          </Typography>
        </Box>
        <Box className={`${classes.highItem} ${summary.highIssues === 0 && classes.noIssueItem}`}>
          <Typography variant="subtitle1">
            High
          </Typography>
          <Typography >
            {summary.highIssues}
          </Typography>
        </Box>
        <Box className={`${classes.mediumItem} ${summary.mediumIssues === 0 && classes.noIssueItem}`}>
          <Typography variant="subtitle1">
            Medium
          </Typography>
          <Typography >
            {summary.mediumIssues}
          </Typography>
        </Box>
        <Box className={`${classes.lowItem} ${summary.lowIssues === 0 && classes.noIssueItem}`}>
          <Typography variant="subtitle1">
            Low
          </Typography>
          <Typography >
            {summary.lowIssues}
          </Typography>
        </Box>
      </Box>

      {
        groupData.length > 0 && <>
          <Typography className={classes.titleItem} variant="h6">
            Issues
          </Typography>
          <Paper>
            <StyledTabs value={groupValue} onChange={handleGroupChange} indicatorColor="primary"
              textColor="primary"
              centered
            >
              {
                groupData.map(([priority]) => <StyledTab key={priority} value={priority} label={priority} />)
              }
            </StyledTabs>
            {
              groupData.map(([priority, datas], index) => <TabPanel key={priority} value={priority} hidden={priority !== groupValue}>
                {datas.map(issue => (
                  <Accordion key={issue.data.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle1">
                        {issue.data.issueName} ({issue.data.friority})
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <Typography color="textSecondary">
                          <span className={classes.subTitle}>Found Date: </span>{moment(issue.data.foundDate).format('YYYY-MM-DD HH:mm')}
                        </Typography>
                        <Typography color="textSecondary">
                          <span className={classes.subTitle}>File: </span>{issue.data.fullFileName}
                        </Typography>
                        <Typography color="textSecondary">
                          <span className={classes.subTitle}>Brief: </span> <span dangerouslySetInnerHTML={{ __html: issue.data.brief }}></span>
                        </Typography>
                        <Typography color="textSecondary">
                          <span className={classes.subTitle}>Detail: </span> <span dangerouslySetInnerHTML={{ __html: issue.data.detail }}></span>
                        </Typography>
                        <Typography color="textSecondary">
                          <span className={classes.subTitle}>Recommendation: </span> <span dangerouslySetInnerHTML={{ __html: issue.data.recommendation }}></span>
                        </Typography>
                        <Typography color="textSecondary">
                          <span className={classes.subTitle}>References: </span> <span dangerouslySetInnerHTML={{ __html: issue.data.references }}></span>
                        </Typography>
                        {
                          issue.data.tips && issue.data.tips !== '' && <Typography color="textSecondary">
                            <span className={classes.subTitle}>Tips: </span>{issue.data.tips}
                          </Typography>
                        }
                        {
                          issue.data.traceNodes?.[0] && <>
                            <Typography color="textSecondary">
                              <span className={classes.subTitle}>Trace Nodes: </span>
                            </Typography>
                            <Table className={classes.nodeTable} >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Info</TableCell>
                                  <TableCell align="right">Type</TableCell>
                                  <TableCell align="right">Tip</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {issue.data.traceNodes[0].map((row) => (
                                  <TableRow key={`${row.nodeType}-${row.text}-${row.toolTip}`}>
                                    <TableCell component="th" scope="row">
                                      {row.text}
                                    </TableCell>
                                    <TableCell align="right">{row.nodeType}</TableCell>
                                    <TableCell align="right">{row.toolTip}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </>
                        }
                      </div>

                    </AccordionDetails>
                  </Accordion>
                ))}

              </TabPanel>)
            }
          </Paper>
        </>
      }
    </CommonDialogContent>
  </CommonDialog>
}

export default FortifyReport;