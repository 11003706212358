import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import { useStateGettersContext } from '../../store';
import MetaInfo from './MetaInfo';
import { cb2Promise, useHandleAwsAuthErr } from '../../common/AwsUtil';
import ExecutionHistory from './ExecutionHistory';
import InfraArea from './InfraArea';

import infoBlackIcon from '../../assets/icons/info_black.png';
import useDeployEnv from './useDeployEnv';
import Cost from './Cost';
import ScanReport from './ScanReport';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '30px 70px'
    },
    title: {
        color: '#454545',
        fontSize: '22px',
        fontWeight: 700,
        paddingLeft: '8px',
        position: 'relative'
    },
    infraContainer: {
        marginTop: '28px'
    },
    executionTitleContainer: {
        marginTop: '28px'
    },
    executionTitle: {
        color: '#454545',
        fontSize: '22px',
        fontWeight: 600,
        paddingLeft: '8px',
        display: 'inline-block'
    },
    executionInfo: {
        color: '#4D4E53',
        fontSize: '16px',
        fontWeight: 400,
        display: 'inline-block',
        marginLeft: '24px'
    },
    executionInfoIcon: {
        width: '18px',
        height: '18px',
        position: 'relative',
        top: '3px',
        paddingRight: '8px'
    }
}));

const Project = () => {
    const classes = useStyles();
    const params = useParams();
    const storeGetters = useStateGettersContext();
    const handleAwsAuthErr = useHandleAwsAuthErr();

    const [pipelineOwner, setPipelineOwner] = useState("");
    const [projectShortName, setProjectShortName] = useState("");
    const [pipetype, setPipetype] = useState(null);
    const [pipelineStages, setPipelineStages] = useState(null);
    const [isDataLakeAccount, setIsDataLakeAccount] = useState(false);
    const [isPipelineMetaLoading, setIsPipelineMetaLoading] = useState(false);

    const projectName = params.id;

    useEffect(() => {
        if (projectName) {
            let didMount = true;
            setIsPipelineMetaLoading(true);
            setPipelineOwner("");
            setProjectShortName("");
            setIsDataLakeAccount(false);
            setPipetype(null);
            const codepipeline = new AWS.CodePipeline(storeGetters.readonlyKeys);
            const params = {
                name: projectName
            };
            cb2Promise(codepipeline, 'getPipeline', params).then((data) => {
                setPipelineStages(data.pipeline.stages);
                let pipeLineArn = data.metadata.pipelineArn;
                var params = {
                    resourceArn: pipeLineArn
                };
                return cb2Promise(codepipeline, 'listTagsForResource', params);
            }).then((data) => {
                if (!didMount) return;
                data.tags.forEach(function (tag) {
                    if (tag.key === "Owner") {
                        setPipelineOwner(tag.value);
                    } else if (tag.key === "ProjectShortName") {
                        setProjectShortName(tag.value);
                    } else if ((tag.key === "dataLakeAccount" || tag.key === "DataLakeAccount") && tag.value === 'true') {
                        setIsDataLakeAccount(true);
                    } else if (tag.key === "Pipetype") {
                        setPipetype(tag.value);
                    }
                });

                setIsPipelineMetaLoading(false);
            }).catch((err) => {
                handleAwsAuthErr(err);

                if (!didMount) return;
                setIsPipelineMetaLoading(false);
            });

            return () => didMount = false;
        }
    }, [projectName, storeGetters.readonlyKeys, handleAwsAuthErr]);

    const {envExecutionIdMap, envVersionMap} = useDeployEnv(projectName, pipetype, pipelineOwner, pipelineStages, isDataLakeAccount, null, true);

    return <Box className={classes.container} >
        <Typography className={classes.title} variant="h2" >
            Pipeline Details
        </Typography>
        <Cost projectName={projectName} />
        <MetaInfo projectName={projectName} owner={pipelineOwner} isLoading={isPipelineMetaLoading} isDataLakeAccount={isDataLakeAccount}/>

        <ScanReport projectName={projectName} owner={pipelineOwner} />

        <InfraArea projectShortName={projectShortName} pipelineOwner={pipelineOwner} pipetype={pipetype} />

        <Box className={classes.executionTitleContainer}>
            <Typography className={classes.executionTitle} variant="h2" >Pipeline Execution History</Typography>
            <Typography className={classes.executionInfo} variant="caption" ><img src={infoBlackIcon} alt='info' className={classes.executionInfoIcon} />It is showing only last one year's pipeline execution history.</Typography>
        </Box>
        <ExecutionHistory projectName={projectName} owner={pipelineOwner} envExecutionIdMap={envExecutionIdMap} envVersionMap={envVersionMap} pipetype={pipetype} />
        
    </Box>
}

export default Project;
