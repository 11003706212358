import React, { useEffect, useMemo, useState } from 'react';

import { Link, useParams } from "react-router-dom";
import AWS from "aws-sdk";
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useStateGettersContext } from '../../store';
import { useHandleAwsAuthErr } from '../../common/AwsUtil';
import CommonTooltip from '../../components/ui/CommonTooltip';
import ExecutionActionList from './ExecutionActionList';

import smileIcon from '../../assets/icons/smile_white.png';
import statusApproveIcon from '../../assets/icons/status_approve.png';
import statusAbandonedIcon from '../../assets/icons/status_abandoned.png';
import statusEmptyIcon from '../../assets/icons/status_empty.png';
import statusFailedIcon from '../../assets/icons/status_failed.png';
import statusSuccessIcon from '../../assets/icons/status_success.png';
import statusOngoingIcon from '../../assets/icons/status_ongoing.png';
import useDeployEnv from '../Project/useDeployEnv';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 30px'
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: '40px'
    },
    loading: {
        color: '#E66400',
    },
    breadcrumb: {
        color: '#4C4948',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px'
    },
    breadcrumbLink: {
        color: '#AC4B00',
        textDecorationLine: 'underline',
    },
    mainContainer: {
        padding: '30px 60px',
    },
    titleContainer: {
        position: 'relative'
    },
    committerContainer: {
        marginBottom: '8px'
    },
    committer: {
        fontWeight: 600,
        backgroundColor: '#104A6B',
        padding: '0 10px'
    },
    'dot-Succeeded': {
        width: '10px',
        height: '10px',
        background: '#11A38B',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-InProgress': {
        width: '10px',
        height: '10px',
        background: '#D8B200',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-Failed': {
        width: '10px',
        height: '10px',
        background: '#E83646',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-Cancelled': {
        width: '10px',
        height: '10px',
        background: '#4C4948',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-Superseded': {
        width: '10px',
        height: '10px',
        background: 'linear-gradient(#4C4948 50%,#FFF 50%);',
        backgroundSize: '100% 1.5px',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    versionContainer: {
        borderRadius: '11px 4px 4px 11px',
        border: '1.5px solid currentColor',
        background: '#FFF',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
        display: 'inline-block',
        padding: '1px 8px',
        marginRight: '16px',
        verticalAlign: 'middle',
        '& > *': {
            verticalAlign: 'middle',
            display: 'inline-block',
        }
    },
    titleInfoContainer: {
        marginBottom: '16px'
    },
    'version-text': {
        lineHeight: '14px',
        fontSize: '13px',
        fontWeight: 600,
    },
    'executionName': {
        color: '#4D4E53',
        fontSize: '18px',
        fontWeight: 400,
        display: 'inline-block',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 400px)'
    },
    'statusContainer': {
        position: 'absolute',
        right: 0,
        bottom: "8px",
        width: '280px',
        '& .MuiGrid-item': {
            padding: 0
        },
        '& *': {
            verticalAlign: 'middle'
        }
    },
    'statusText': {
        color: '#4D4E53',
        fontSize: '13px',
        fontWeight: 400,
        paddingLeft: '8px',
        display: 'inline-block'
    },
    statusIcon: {
        width: '18px',
        height: '18px',
    },
    smileIcon: {
        width: '16px',
        height: '16px',
    },
    'executionhistory-comment-envitem': {
        display: 'inline-block',
        border: '1.5px solid currentColor',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '11px',
        textAlign: 'center',
        fontSize: '13px',
        fontWeight: 600,
        color: '#4C4948',
        padding: '3px 19px',
        marginLeft: '6px',
        fontFamily: 'Roboto',
        background: '#FFFFFF'
    },
    envLoading: {
        color: '#E66400',
        marginLeft: '6px',
        position: 'relative',
        top: '1px'
    }
}))

const colorMap = {
    Succeeded: '#11A38B',
    InProgress: '#D8B200',
    Failed: '#E83646',
    Cancelled: '#4C4948',
    Superseded: '#4C4948'
};

const Execution = () => {
    const params = useParams();
    const storeGetters = useStateGettersContext();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const [executionActionList, setExecutionActionList] = useState([]);
    const [pipelineType, setPipelineType] = useState("");
    const [pipelineOwner, setPipelineOwner] = useState("");
    const [datalakeAccount, setDatalakeAccount] = useState(false);
    const [executionDetail, setExecutionDetail] = useState(null);
    const [reloadListTrigger, setReloadListTrigger] = useState(false);
    const [commitInfo, setCommitInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [version, setVersion] = useState(null);
    const [pipelineStages, setPipelineStages] = useState(null);
    const projectName = params.projectname;
    const executionId = params.id;

    const { envExecutionIdMap, envVersionMap } = useDeployEnv(projectName, pipelineType, pipelineOwner, pipelineStages, datalakeAccount, executionActionList);
    

    useEffect(() => {
        const codepipeline = new AWS.CodePipeline(storeGetters.readonlyKeys);
        let didMount = true;
        setLoading(true);
        codepipeline.listActionExecutions({
            pipelineName: projectName,
            filter: {
                pipelineExecutionId: executionId
            }
        }, function (err, data) {
            if (!didMount) return;
            if (err) {
                if (handleAwsAuthErr(err)) {
                    console.error(err);
                }
            } else {
                setExecutionActionList(data.actionExecutionDetails);
            }
            setFirstLoading(false);
            setLoading(false);
        });

        return () => didMount = false;
    }, [reloadListTrigger, storeGetters.readonlyKeys, projectName, executionId, handleAwsAuthErr]);

    useEffect(() => {
        const interval = setInterval(() => {
            setReloadListTrigger((flag) => !flag);
        }, 15000);

        return () => {
            clearInterval(interval);
        }
    }, [])


    useEffect(() => {
        const codepipeline = new AWS.CodePipeline(storeGetters.readonlyKeys);
        let didMount = true;

        codepipeline.getPipeline({ name: projectName }, function (err, data) {
            if (!didMount) return;
            if (err) {
                if (handleAwsAuthErr(err)) {
                    console.error(err);
                }
            } else {
                setPipelineStages(data.pipeline.stages);
                codepipeline.listTagsForResource({
                    resourceArn: data.metadata.pipelineArn
                }, function (err, data) {
                    if (!didMount) return;
                    if (err) {
                        console.log(err, err.stack); // an error occurred
                    }
                    else {
                        data.tags.forEach(function (tag) {
                            if (tag.key === "Pipetype") {
                                setPipelineType(tag.value);
                            } else if (tag.key === "Owner") {
                                setPipelineOwner(tag.value);
                            }
                            else if (tag.key === "dataLakeAccount" || tag.key === "DataLakeAccount") {
                                setDatalakeAccount(tag.value && tag.value === "true" ? true : false);
                            }
                        });
                    }
                });
            }
        })

        codepipeline.getPipelineExecution({
            pipelineExecutionId: executionId,
            pipelineName: projectName
        }, function (err, data) {
            if (!didMount) return;
            if (err) {
                if (handleAwsAuthErr(err)) {
                    console.error(err);
                }
            } else {
                setExecutionDetail(data.pipelineExecution);
                if (data.pipelineExecution.artifactRevisions && data.pipelineExecution.artifactRevisions[0]) {
                    const codecommit = new AWS.CodeCommit(storeGetters.readonlyKeys);
                    codecommit.getCommit({
                        commitId: data.pipelineExecution.artifactRevisions[0].revisionId,
                        repositoryName: projectName
                    }, function (err, data) {
                        if (!didMount) return;
                        if (err) {
                            if (handleAwsAuthErr(err)) {
                                console.error(err);
                            }
                        } else {
                            setCommitInfo(data.commit);
                        }
                    });
                }
            }
        });

        const dynamoDB = new AWS.DynamoDB(storeGetters.readonlyKeys);
        dynamoDB.query({
            ExpressionAttributeValues: {
                ':repository': { S: projectName },
                ':executionId': { S: executionId }
            },
            FilterExpression: 'pipelineexecutionid = :executionId',
            KeyConditionExpression: 'repositoryname = :repository',
            ProjectionExpression: 'repositoryname, version, pipelineexecutionid',
            TableName: 'pipeline-version-manager'
        }, function (err, data) {
            if (!didMount) return;
            if (err) {
                if (handleAwsAuthErr(err)) {
                    console.error(err);
                }
            } else if (data.Items[0] && data.Items[0].version && data.Items[0].version.N) {
                setVersion(data.Items[0].version.N);
            }
        });

        return () => didMount = false;
    }, [storeGetters.readonlyKeys, projectName, executionId, handleAwsAuthErr]);

    const subTitle = useMemo(() => {
        return `${projectName} ${commitInfo ? `- ${commitInfo.message}` : ''} - ${executionId}`;
    }, [projectName, commitInfo, executionId])

    useEffect(() => {
        setFirstLoading(true);
        setExecutionActionList([]);
        setExecutionDetail(null);
        setCommitInfo(null);
        setVersion(null);
    }, [projectName, executionId])

    const classes = useStyles();
    return <Box className={classes.container}>
        <Typography className={classes.breadcrumb}>
            <Link className={classes.breadcrumbLink} to={`/project/${projectName}`}>Pipeline Detail / History</Link>&nbsp;&nbsp;&gt;&nbsp;&nbsp;Pipeline Process
        </Typography>
        <Box className={classes.mainContainer}>
            <Box className={classes.titleContainer}>
                {
                    commitInfo &&
                    <Box className={classes.committerContainer}>
                        <CommonTooltip title='Committer'>
                            <Chip className={classes.committer} label={commitInfo.author.name} icon={<img src={smileIcon} alt="committer" className='smileIcon' />} color="primary" size="small"></Chip>
                        </CommonTooltip>

                        {
                            pipelineType.toLowerCase() === 'application' &&
                            envExecutionIdMap?.filter(item => item.status !== "NO_PERMISSION").map(item => {
                                return <span key={item.env} className={classes['executionhistory-comment-envitem']}>
                                    {item.env.split('-')[2]}
                                    {item.status === 'InProgress' && <CircularProgress className={classes.envLoading} size={10} />}
                                </span>
                            })
                        }
                        {
                            pipelineType.toLowerCase() === 'lambda' &&
                            envVersionMap?.filter(item => item.pipelineVersion && item.pipelineVersion === version).map(item => {
                                return <span key={item.env} className={classes['executionhistory-comment-envitem']}>
                                    {item.env.split('-')[item.env.split('-').length - 1]}
                                </span>
                            })
                        }
                    </Box>
                }
                <Box className={classes.titleInfoContainer}>
                    {
                        executionDetail &&
                        <div className={classes.versionContainer} style={{ color: colorMap[executionDetail.status] }}>
                            <div className={classes[`dot-${executionDetail.status}`]}></div>
                            <Typography className={classes[`version-text`]}>{version ? version : executionId}</Typography>
                        </div>
                    }
                    <CommonTooltip title={subTitle} interactive>
                        <Typography className={classes.executionName}>
                            {subTitle}
                        </Typography>
                    </CommonTooltip>
                </Box>

                <Grid container className={classes.statusContainer}>
                    <Grid container item xs={12}>
                        <Grid item xs={4}>
                            <img src={statusSuccessIcon} alt="success" className={classes.statusIcon} />
                            <Typography className={classes.statusText}>Success</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <img src={statusFailedIcon} alt="failed" className={classes.statusIcon} />
                            <Typography className={classes.statusText}>Failed</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <img src={statusAbandonedIcon} alt="abandoned" className={classes.statusIcon} />
                            <Typography className={classes.statusText}>Abandoned</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: '12px' }}>
                        <Grid item xs={4}>
                            <img src={statusOngoingIcon} alt="on-going" className={classes.statusIcon} />
                            <Typography className={classes.statusText}>Ongoing</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <img src={statusApproveIcon} alt="approve" className={classes.statusIcon} />
                            <Typography className={classes.statusText}>Approval</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <img src={statusEmptyIcon} alt="empty" className={classes.statusIcon} />
                            <Typography className={classes.statusText}>Empty</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.contentContainer}>
                {
                    loading && firstLoading && <Box className={classes.loadingContainer}><CircularProgress className={classes.loading} /></Box>
                }
            </Box>
            <ExecutionActionList
                projectName={projectName}
                pipelineType={pipelineType}
                pipelineOwner={pipelineOwner}
                pipelineExecutionId={executionId}
                actionList={executionActionList}
                datalakeAccount={datalakeAccount}
                envExecutionIdMap={envExecutionIdMap}
                version={version}
                envVersionMap={envVersionMap}
                onRefreshRequest={() => setReloadListTrigger(!reloadListTrigger)}
            />
        </Box>
    </Box>
}

export default Execution
