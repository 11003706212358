import React from 'react';
import { NavLink } from "react-router-dom";

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import costIcon from '../../assets/icons/cost.svg';
import vulnerabilityIcon from '../../assets/icons/vulnerability.svg';
 
const useStyles = makeStyles((theme) => ({
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '26px 0'
    },
    tab: {
        color: '#4C4948',
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: 400,
        borderBottom: '0.5px solid #4C4948',
        padding: '8px 24px',
        display: 'block',
        position: 'relative',
        '& > *': {
            verticalAlign: 'middle'
        },
        '&::before': {
            content: '""',
            display: 'block',
            width: '0.5px',
            height: '30px',
            background: '#4C4948',
            position: 'absolute',
            left: 0,
            bottom: '4px'
        },
        '&:last-of-type::after': {
            content: '""',
            display: 'block',
            width: '0.5px',
            height: '30px',
            background: '#4C4948',
            position: 'absolute',
            right: 0,
            bottom: '4px'
        }
    },
    disabledTab: {
        pointerEvents: 'none',
        cursor: 'none'
    },
    restArea: {
        flex: 1,
        borderBottom: '0.5px solid #4C4948',
    },
    activeTab: {
        background: '#F7EED7',
        border: '0.5px solid #4C4948',
        borderBottom: 'none',
        fontWeight: 600,
        '&::after': {
            width: '0 !important'
        },
        '&::before': {
            width: '0 !important'
        },
        '& + a::before': {
            width: '0 !important'
        }
    },
    tabIcon: {
        width: '24px',
        height: '24px'
    }
}));

const HomeNav = () => {
    const classes = useStyles();

    return <Box className={classes.tabContainer}>
        <NavLink className={classes.tab} activeClassName={classes.activeTab} to="/dashboard/cost">
            <img src={costIcon} alt='Cost' className={classes.tabIcon} /> Cost
        </NavLink>
        <NavLink className={classes.tab} activeClassName={classes.activeTab} to="/dashboard/vulnerability">
            <img src={vulnerabilityIcon} alt='Vulnerability' className={classes.tabIcon} /> Vulnerability
        </NavLink>
        <div className={classes.restArea}></div>
    </Box>;
}

export default HomeNav;