import React, { Fragment, useEffect, useMemo, useState } from 'react';
import AWS from "aws-sdk";
import { Link } from "react-router-dom";

import Box from '@material-ui/core/Box';
import asendingIcon from '../../assets/icons/sort_asending.png';
import decendingIcon from '../../assets/icons/sort_decending.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

import { useStateGettersContext } from '../../store';
import { useHandleAwsAuthErr } from '../../common/AwsUtil';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '24px 70px',
    },
    orderContainer: {
        padding: '10px 22px',
        color: '#4C4948',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        background: '#ECECEC',
        fontFamily: 'Roboto',
        borderBottom: '0.5px solid #CCCCCC',
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    sortIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '6px',
        cursor: 'pointer'
    },
    letterArea: {
        color: '#4C4948',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        padding: '10px 23px',
        background: '#ECECEC',
        borderBottom: '0.5px solid #CCCCCC'
    },
    projectItem: {
        background: '#FFFFFF',
        display: 'block',
        padding: '18px 22px',
        color: '#4C4948',
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '14px',
        borderBottom: '0.5px solid #CCCCCC',
        "&:hover": {
            background: '#F7EED7'
        }
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: '40px'
    },
    loading: {
        color: '#E66400',
    }
}));

const Pipelines = () => {
    const classes = useStyles();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const [loading, setLoading] = useState(false);
    const [orderType, setOrderType] = useState('asending'); // 'asending' | 'decending'
    const storeGetters = useStateGettersContext();
    const [projectDataList, setProjectDataList] = useState([]);

    useEffect(() => {
        setLoading(true);
        const codepipeline = new AWS.CodePipeline(storeGetters.readonlyKeys);
        const params = {};
        codepipeline.listPipelines(params, function (err, data) {
            setLoading(false);
            if (err) {
                handleAwsAuthErr(err);
            } else {
                console.log('aaa', data.pipelines.length)
                setProjectDataList(data.pipelines);
            }
        });
    }, [storeGetters.readonlyKeys, handleAwsAuthErr]);

    const projectListByLetter = useMemo(() => {
        return projectDataList
            .sort((a, b) => {
                if (orderType === 'asending')
                    return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
                return b.name.toUpperCase() > a.name.toUpperCase() ? 1 : -1
            })
            .reduce((total, project) => {
                const tagItem = total.find(item => item.tag === project.name[0].toUpperCase());
                if (tagItem) {
                    tagItem.list.push(project)
                } else {
                    total.push({
                        tag: project.name[0].toUpperCase(),
                        list: [project]
                    })
                }

                return total;
            }, [])
    }, [projectDataList, orderType])

    return <Box className={classes.container}>
        <Box className={classes.orderContainer}>
            Pipeline/Repository Name
            <img src={orderType === 'asending' ? asendingIcon : decendingIcon} onClick={() => setOrderType(orderType === 'asending' ? 'decending' : 'asending')} alt='sort icon' className={classes.sortIcon} />
        </Box>
        {
            loading && <Box className={classes.loadingContainer}><CircularProgress className={classes.loading} /></Box>
        }
        {
            projectListByLetter.map(item => {
                return <Fragment key={item.tag}>
                    <Box key={item.tag} className={classes.letterArea}>{item.tag}</Box>
                    {
                        item.list.map(projectItem => <Link to={`/project/${projectItem.name}`} key={projectItem.name} className={classes.projectItem}>{projectItem.name}</Link>)
                    }
                </Fragment>
            })
        }
    </Box>
}

export default Pipelines;