import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { makeStyles } from "@material-ui/core/styles";
import { useStateGettersContext } from "../../store";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";

import reportIcon from "../../assets/icons/report.svg";
import { cb2Promise, useHandleAwsAuthErr } from "../../common/AwsUtil";
import FortifyReport from "./FortifyReport";
import CommonButton2 from "../../components/ui/CommonButton2";
import { COLOR_MAP } from "../Vulnerabilityreport";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "28px",
  },
  executionTitle: {
    color: "#454545",
    fontSize: "22px",
    fontWeight: 600,
    paddingLeft: "8px",
    display: "inline-block",
  },
  executionInfo: {
    color: "#4D4E53",
    fontSize: "16px",
    fontWeight: 400,
    display: "inline-block",
    marginLeft: "24px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "14px",
  },
  summaryWrapper: {
    color: "#FFFFFF",
    margin: "0 22px 0 8px",

    "& div": {
      fontSize: "13px",
      display: "inline-block",
      padding: "3px 19px",
    },
    "& div h6": {
      fontSize: "13px",
      lineHeight: 1.2,
    },
  },
  noReportWrapper: {
    "& div": {
      background: "#ECECEC !important",
      color: "#CCCCCC !important",
    }
  },
  noIssueItem: {
    background: "#11A38B !important",
    position: "relative",
    "& ~ &::before": {
      position: "absolute",
      left: 0,
      top: "2px",
      bottom: "2px",
      content: "' '",
      display: "block",
      background: "#ffffff",
      width: "1px",
    },
  },
  summaryItem: {
    background: "#4C4948",
    borderEndStartRadius: "4px",
    borderStartStartRadius: "4px",
  },
  criticalItem: {
    background: COLOR_MAP.Critical,
  },
  highItem: {
    background: COLOR_MAP.High,
  },
  mediumItem: {
    background: COLOR_MAP.Medium,
  },
  lowItem: {
    background: COLOR_MAP.Low,
    borderEndEndRadius: "4px",
    borderStartEndRadius: "4px",
  },
  reportButton: {
    paddingLeft: "10px",
    paddingRight: "10px",
    "& *": {
      verticalAlign: "middle",
    },
  },
  reportBtnText: {
    paddingLeft: "6px",
    position: "relative",
    top: "1px",
  },
}));

export function useScanReportSummaryInfo(reportJson) {
  const summary = (reportJson ?? []).reduce(
    (total, item) => {
      const level = item.data?.friority;
      switch (level) {
        case "Low":
          total.lowIssues++;
          break;
        case "Medium":
          total.mediumIssues++;
          break;
        case "High":
          total.highIssues++;
          break;
        case "Critical":
          total.criticalIssues++;
          break;

        default:
          break;
      }

      return total;
    },
    {
      totalIssues: 0,
      criticalIssues: 0,
      highIssues: 0,
      mediumIssues: 0,
      lowIssues: 0,
    }
  );

  summary.totalIssues =
    summary.criticalIssues +
    summary.highIssues +
    summary.mediumIssues +
    summary.lowIssues;

  return summary;
}

const ScanReport = ({ projectName, owner }) => {
  const classes = useStyles();
  const storeGetters = useStateGettersContext();
  const handleAwsAuthErr = useHandleAwsAuthErr();

  const [fortifyReportOpen, setFortifyReportOpen] = useState(false);

  const [reportJson, setReportJson] = useState(null);
  const [lastScanDate, setLastScanDate] = useState(null);

  useEffect(() => {
    if (projectName && owner) {
      const fetchData = async () => {
        setReportJson(null);
        try {
          const s3 = new AWS.S3(storeGetters.readonlyKeys);

          const s3Params = {
            Bucket: `ud-pipeline-artifacts-372158068517`,
            Key: `sast-scan-reports-latest/${projectName}__${owner.toLowerCase()}.json`,
          };
          const data = await cb2Promise(s3, "getObject", s3Params);
          setLastScanDate(
            moment(data.LastModified).format("YYYY-MM-DD HH:mm:ss")
          );
          const jsonData = JSON.parse(data.Body.toString("utf-8"));
          setReportJson(jsonData);
        } catch (err) {
          if (!handleAwsAuthErr(err)) {
            console.error("Error fetching data:from S3: ", err);
            setReportJson([]);
            setLastScanDate(null);
          }
        }
      };

      fetchData();
    }
  }, [storeGetters.readonlyKeys, projectName, owner, handleAwsAuthErr]);

  const summary = useScanReportSummaryInfo(reportJson);

  if (!reportJson) return null;

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.executionTitle} variant="h2">
        SAST Vulnerability Report
      </Typography>
      <Typography className={classes.executionInfo} variant="caption">
        Last scan date: {lastScanDate ? lastScanDate : '-'}
      </Typography>
      <Box className={classes.contentWrapper}>
        <Box className={`${classes.summaryWrapper} ${
              !lastScanDate && classes.noReportWrapper
            }`}>
          <Box
            className={`${classes.summaryItem} ${
              summary.totalIssues === 0 && classes.noIssueItem
            }`}
          >
            <Typography variant="subtitle1">
              Total {lastScanDate ? summary.totalIssues : '-'}
            </Typography>
          </Box>
          <Box
            className={`${classes.criticalItem} ${
              summary.criticalIssues === 0 && classes.noIssueItem
            }`}
          >
            <Typography variant="subtitle1">
              Critical {lastScanDate ? summary.criticalIssues : '-'}
            </Typography>
          </Box>
          <Box
            className={`${classes.highItem} ${
              summary.highIssues === 0 && classes.noIssueItem
            }`}
          >
            <Typography variant="subtitle1">
              High {lastScanDate ? summary.highIssues : '-'}
            </Typography>
          </Box>
          <Box
            className={`${classes.mediumItem} ${
              summary.mediumIssues === 0 && classes.noIssueItem
            }`}
          >
            <Typography variant="subtitle1">
              Medium {lastScanDate ? summary.mediumIssues : '-'}
            </Typography>
          </Box>
          <Box
            className={`${classes.lowItem} ${
              summary.lowIssues === 0 && classes.noIssueItem
            }`}
          >
            <Typography variant="subtitle1">Low {lastScanDate ? summary.lowIssues : '-'}</Typography>
          </Box>
        </Box>
        {lastScanDate && (
          <CommonButton2
            className={classes.reportButton}
            onClick={() => setFortifyReportOpen(true)}
          >
            <img src={reportIcon} alt="report icon" />
            <span className={classes.reportBtnText}>Report</span>
          </CommonButton2>
        )}
      </Box>

      <FortifyReport
        open={fortifyReportOpen}
        scanData={reportJson}
        onClose={() => setFortifyReportOpen(false)}
      />
    </Box>
  );
};

export default ScanReport;
