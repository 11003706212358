import React from 'react';

import {
    Box, Typography, makeStyles
} from '@material-ui/core';
import FilterParams from './FilterParams';

const useStyles = makeStyles((theme) => ({
    container: {
        background: '#ffffff',
        height: '100%'
    },
    header: {
        background: '#ececec'
    },
    title: {
        lineHeight: '70px',
        paddingLeft: '20px',
        fontSize: '16px',
        fontWeight: 600,
        color: '#4D4E53'
    },
    content: {
        padding: '18px 22px'
    },
    divider: {
        margin: '2px 0 28px'
    }
}));

const ParamsArea = (props) => {
    const classes = useStyles();
    const {value, onChange, productOwners, componentNames} = props;
    const setFilters = (param) => {
        onChange({
            ...value,
            filters: param
        })
    }
    return <Box className={classes.container}>
        <Box className={classes.header}>
            <Typography className={classes.title}>Report Parameters</Typography>
        </Box>
        <Box className={classes.content}>
            <FilterParams productOwners={productOwners} componentNames={componentNames} account={value.accountParam} value={value.filters} onChange={setFilters}/>
        </Box>
    </Box>
}

export default ParamsArea;